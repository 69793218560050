import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const HookFormInput = ({
  // react-hook-form props
  control,
  errors,

  label,
  placeholder,
  fieldKey,
  isSelect,
  isDatePicker,
  options,
  formControlSx = { minWidth: 120 },
  ...props
}) => {
  const inputName = label ? label.toLowerCase().replace(/ /g, "-") : props.name;

  const rules = {
    maxLength: props.multiline ? 500 : 40,
    required: true,
  };

  const error = errors[fieldKey];
  const errorMessagesByType = {
    required: "שדה חובה",
    maxLength: "Too long",
  };
  const errorMessage = error && errorMessagesByType[error.type];

  const InputComponent = isSelect
    ? Select
    : //   : isDatePicker
      //   ? React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
      //       <LocalizationProvider dateAdapter={AdapterDayjs}>
      //         <MobileDatePicker
      //           name={name}
      //           label={label}
      //           ref={ref}
      //           onChange={onChange}
      //           onBlur={onBlur}
      //           renderInput={(params) => (
      //             <TextField
      //               sx={{ minWidth: 300 }}
      //               error={Boolean(error)}
      //               {...params}
      //             />
      //           )}
      //         />
      //       </LocalizationProvider>
      //     ))
      TextField;
  return (
    <Controller
      name={fieldKey}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl sx={formControlSx}>
          {isSelect && <InputLabel>{label}</InputLabel>}
          <InputComponent
            fullWidth
            label={label}
            placeholder={placeholder}
            name={inputName}
            inputProps={{ ...rules, readOnly: props.readOnly }}
            sx={{ minWidth: 300 }}
            error={Boolean(error)}
            {...field}
            {...props}
          >
            {isSelect && options
              ? options.map((i) => (
                  <MenuItem value={i._id} key={i._id}>
                    {i.title}
                  </MenuItem>
                ))
              : null}
          </InputComponent>
          {error ? (
            <FormHelperText error={Boolean(error)}>
              {errorMessage}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
};

export default HookFormInput;
