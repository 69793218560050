import React, { useEffect, useState } from 'react';
import {Typography } from "@mui/material";
import { dataGridSx } from "../../plugins/mui";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import { appFetch } from "../../utils/fetch";

const AllOperatorsPage = () => {
  const [payments, setPayments] = useState([]);

  const columns = [
    {
      field: "Name",
      headerName: "שם הספק",
      minWidth: 180,
    },
    {
        field: "business_name",
        headerName: "שם העסק",
        minWidth: 200,
      },
      {
        field: "IDOperator",
        headerName: "תעודת זהות",
        minWidth: 100,
      },
      {
        field: "field6",
        headerName: "טלפון",
        minWidth: 110,
        renderCell: (params) => {
          let phoneNumber = params.value;
          if (phoneNumber.startsWith("+972")) {
            phoneNumber = phoneNumber.replace("+972", "0");
          }
          return <div>{phoneNumber}</div>;
        }
      }
      ,
      {
        field: "Email",
        headerName: "אימייל",
        minWidth: 200,
      },
   
    {
      field: "field1",
      headerName: "סטטוס",
      minWidth: 110,
    },
    {
      field: "field11",
      headerName: "סוג",
      width:  150,
    },
   
  ];

 
  useEffect(() => {
    (async () => {
      try {
        const res = await appFetch('api/zohoOrganizations/Operators'); 
        if (!res.ok) {
          throw new Error('Failed to fetch payments');
        }
        const data = await res.json();
        console.log("im here");
        console.log(data.data);
        setPayments(data.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    })();
  }, []);

  // return (
  //   <div>
  //     <h1>Hello from mosad</h1>
  //     <ul>
  //       {Array.isArray(mosads) && mosads.length > 0 && mosads.map((mosad, index) => (
  //         <li key={index}>{mosad.Name}</li>
  //       ))}
  //     </ul>
  //   </div>
  // );
  return (
    <Container sx={{ height: '100vh' }}>
<Typography mt={10} mb={2} variant="h3" color="appPink.main">
        רשימת מפעילים
      </Typography>
      <DataGrid
        rows={payments || []}
        columns={columns}
        getRowId={(row) => row.id}
        disableSelectionOnClick
        // onRowClick={({ row }) => {
        //   if (!isSmUp) {
        //     openDetailsDialog();
        //     setSelectedItem(row);
        //   }
        // }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sort
        loading={payments === undefined}
        sx={{
          ...dataGridSx,
          width: { xs: "500px", sm: 1 },

          px: { xs: 1.5, sm: 3 },
          py: 1.5,
          ".MuiDataGrid-columnHeaderTitle": {
            color: "appPink.main",
          },
          ".MuiDataGrid-columnHeaders": {
            borderColor: "appPink.main",
          },
          ".MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          '.MuiDataGrid-cell[data-field="amount"] .MuiDataGrid-cellContent': {
            direction: "rtl",
          },
        }}
      />
</Container>
  );
};

export default AllOperatorsPage;
