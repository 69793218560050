import React, { useEffect, useState , useContext } from 'react';
import {  Typography, InputLabel, Select, MenuItem, FormControl, TextField, Box,  Modal, Grid, Button } from "@mui/material";
import { dataGridSx } from "../../plugins/mui";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import { appFetch } from "../../utils/fetch";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { UserContext } from "../../hooks/useUser";

const AllPaymentsPage = () => {
  const [payments, setPayments] = useState([]);
  const {mosads, setMosads } = useContext(UserContext);

  const [mosadName, setMosadName] = useState('');
  const [payType, setPayType] = useState('');
  const [studentName, setStudentName] = useState('');
  const [studentId, setStudentId] = useState('');
  const [dealNumber, setDealNumber] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedOption, setSelectedOption] = useState('');
  const [payTypeText, setPayTypeText] = React.useState('');
  const [studentText, setStudentText] = useState('');
  const [studentIdText, setStudentIdText] = useState('');
  const [dealNumberText, setDealNumberText] = useState('');
  const [startText, setStartText] = useState('');
  const [endText, setEndText] = useState('');
  

  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const columns = [
    {
      field: "type_pay",
      headerName: "סוג התשלום",
      minWidth: 80,
    },
    {
      field: "Name",
      headerName: "שם הספק",
      width: 200,
      // valueGetter: ({ value }) => {
      //   return value.name.length > 15 ?"..."+ value.name.substring(0, 12)   : value.name;
      // },
    },
    {
      field: "contactid",
      headerName: "שם התלמיד",
      minWidth: 100,
      renderCell: (params) => {
        const { contactid } = params.row;
        return <div>{contactid ? contactid.name : ''}</div>;
      }
    },
    {
      field: "paydate",
      headerName: "תאריך",
      width:  100,
      renderCell: (params) => {
        const dateTimeString = params.value;
        const dateOnly = dateTimeString.split("T")[0];
        return <div>{dateOnly}</div>;
      }
    }, {
      field: "amount",
      headerName: "סכום",
      minWidth: 80,
    },{
      field: "asmahta",
      headerName: "אסמכתא",
      minWidth: 100,
    },{
      field: "field1",
      headerName: "שנה",
      minWidth: 100,
    },{
      field: "field8",
      headerName: "סטטוס",
      minWidth: 100,
    },{
      field: "field3",
      headerName: "סוג",
      minWidth: 100,
    },
    {
      field: "field",
      headerName: "מספר עסקה",
      width:  80,
    },
    
    
  ];

 
  useEffect(() => {
    (async () => {
      try {
        if (!mosadName) {
          return; 
        }
        const res = await appFetch(`api/zohoPayments/paymentsByMosadName/${mosadName}`, {
          method: 'GET',
        });
        if (!res.ok) {
          const errorData = await res.json();
          console.error('Error fetching payments:', errorData.message);
          setErrorMessage(errorData.message);
          setOpen(true);
          return;
        }
        const data = await res.json();
        console.log(data)
        const allPayments=[];
        for (let i = 0; i < data.length; i++) {
          if(data[i]){
            for (let j = 0; j < data[i].length; j++) {
              allPayments.push(data[i][j])   
            }
          }
        }
        setPayments(allPayments);
        console.log(allPayments)
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    })();
  }, [mosadName]);

  useEffect(() => {
    (async () => {
      try {
        if (!payType) {
          return; 
        }
        const res = await appFetch(`api/zohoPayments/paymentsByPayType/${payType}`, {
          method: 'GET',
        });
        if (!res.ok) {
          const errorData = await res.json();
          console.error('Error fetching payments:', errorData.message);
          setErrorMessage(errorData.message);
          setOpen(true);
          return;
        }
        const data = await res.json();
        setPayments(data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    })();
  }, [payType]);

  useEffect(() => {
    (async () => {
      try {
        if (!studentName) {
          return; 
        }
        console.log(studentName)
        const res = await appFetch(`api/zohoPayments/paymentsByStudentName/${studentName}`, {
          method: 'GET',
        });
        if (!res.ok) {
          const errorData = await res.json();
          console.error('Error fetching payments:', errorData.message);
          setErrorMessage(errorData.message);
          setOpen(true);
          return;
        }
        const data = await res.json();
        setPayments(data.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    })();
  }, [studentName]);

  useEffect(() => {
    (async () => {
      try {
        if (!studentId) {
          return; 
        }
        const res = await appFetch(`api/zohoPayments/paymentsByStudentId/${studentId}`, {
          method: 'GET',
        });
        if (!res.ok) {
          const errorData = await res.json();
          console.error('Error fetching payments:', errorData.message);
          setErrorMessage(errorData.message);
          setOpen(true);
          return;
        }
        const data = await res.json();
        setPayments(data.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    })();
  }, [studentId]);

  useEffect(() => {
    (async () => {
      try {
        if (!dealNumber) {
          return; 
        }
        const res = await appFetch(`api/zohoPayments/paymentsByDealNumber/${dealNumber}`, {
          method: 'GET',
        });
        if (!res.ok) {
          const errorData = await res.json();
          console.error('Error fetching payments:', errorData.message);
          setErrorMessage(errorData.message);
          setOpen(true);
          return;
        }
        const data = await res.json();
        setPayments(data.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    })();
  }, [dealNumber]);

  useEffect(() => {
    (async () => {
      try {
        if (!startDate||!endDate) {
          return; 
        }
        console.log(startDate)
        console.log(endDate)
        const res = await appFetch(`api/zohoPayments/paymentsByDates/${startDate}/${endDate}`, {
          method: 'GET',
        });
        if (!res.ok) {
          const errorData = await res.json();
          console.error('Error fetching dates:', errorData.message);
          setErrorMessage(errorData.message);
          setOpen(true);
          return;
        }
        const data = await res.json();
        setPayments(data.data);
      } catch (error) {
        console.error('Error fetching dates:', error);
      }
    })();
  }, [startDate,endDate]);

  const handleSubmit = (e) => {
    e.preventDefault(); // מונע מהטופס להישלח באופן רגיל
    
   // בודק אם המשתמש מילא יותר מאפשרות אחת
if ((selectedOption && (studentText ||startText ||endText||payTypeText||dealNumberText||studentIdText))||
    (studentText&&(startText ||endText||payTypeText||dealNumberText||studentIdText))||
    ((startText || endText)&&(payTypeText||dealNumberText||studentIdText))||
    (payTypeText&&(dealNumberText||studentIdText))||
    (dealNumberText&&studentIdText)){
  setErrorMessage('עליך לבחור רק אפשרות אחת');
  setOpen(true);
  return;
}

// בודק אם המשתמש לא מילא כלום
if (!selectedOption && !studentText && !startText && !endText && !payTypeText&& !studentIdText&& !dealNumberText) {
  setErrorMessage('עליך לבחור את אחת האפשרויות');
  setOpen(true);
  return;
}

// בודק אם המשתמש בחר רק תאריך התחלה
if (startText && !endText) {
  setErrorMessage('עליך לבחור גם תאריך סיום');
  setOpen(true);
  return;
}

// בודק אם המשתמש בחר רק תאריך סיום
if (!startText && endText) {
  setErrorMessage('עליך לבחור גם תאריך התחלה');
  setOpen(true);
  return;
}

// בודק אם תאריך הסיום לא יכול להיות לפני תאריך ההתחלה
if (startText && endText && new Date(startText) > new Date(endText)) {
  setErrorMessage('תאריך הסיום לא יכול להיות לפני תאריך ההתחלה');
  setOpen(true);
  return;
}

  
    // בודק איזו אפשרות נבחרה ומתבצעת התאמה בהתאם
    if (selectedOption) {
      console.log(`נעבור לתשלומים על פי גן ${selectedOption}`);
      setMosadName(selectedOption);
    } 
    if (studentText) {
      console.log(`נעבור לתשלומים לפי שם הילד ${studentText}`);
      setStudentName(studentText);
    } 
    if (startText&&endText) {
      console.log(`נעבור לשלומים לפי תאריכים: ${startDate&&endDate}`);
      setStartDate(startText);
      setEndDate(endText);
    }
    if (payTypeText) {
      console.log(`נעבור לתשלומים לפי סוג תשלום ${payTypeText}`);
      setPayType(payTypeText);
    } 
    if (studentIdText) {
      console.log(`נעבור לתשלומים תז תלמיד ${studentIdText}`);
      setStudentId(studentIdText);
    } 
    if (dealNumberText) {
      console.log(`נעבור לתשלומים על פי סוגע עסקה ${dealNumberText}`);
      setDealNumber(dealNumberText);
    } 
  };
  
  const handlePaymentTypeChange = (event) => {
    setPayTypeText(event.target.value);
  };
  

  const handleCancel = ()=>{
    setMosadName(undefined);
    setPayType('');
    setStudentName('');
    setStudentId('');
    setDealNumber('');
    setStartDate('');
    setEndDate('');
    
    setSelectedOption('');
    setPayTypeText('');
    setStudentText('');
    setStudentIdText('');
    setDealNumberText('');
    setStartText('');
    setEndText('');
  }

  const handleClose = () => {
    setOpen(false);
  };





 
  return (
    <Container sx={{ height: '100vh' }}>
      <Typography mt={10} mb={2} variant="h3" color="appPink.main">
        רשימת תשלומים
      </Typography>
      <Typography mt={2} mb={2} variant="h5" color="appPink.main">
        בחר אפשרות אחת בלבד
      </Typography>
      <form onSubmit={handleSubmit}>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '24px' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', alignItems: 'center' }}>
      <Typography variant="h7" color="appPink.main">
        שם גן
      </Typography>
      <FormControl>
        <InputLabel>שם גן</InputLabel>
        <Select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          sx={{ width: '200px' }}
        >
          <MenuItem value="">בחר מוסד</MenuItem>
          {mosads.map((mosad) => (
            <MenuItem key={mosad.id} value={mosad.Name}>{mosad.Name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="h7" color="appPink.main">
        סוג התשלום
      </Typography>
      <Select
      value={payTypeText}
      onChange={handlePaymentTypeChange}
      autoWidth
    >
      <MenuItem value="אשראי">אשראי</MenuItem>
      <MenuItem value="מזומן">מזומן</MenuItem>
      <MenuItem value="העברה בנקאית">העברה בנקאית</MenuItem>
      <MenuItem value="המחאה">המחאה</MenuItem>
      <MenuItem value="תשלום דרך הישוב">תשלום דרך הישוב</MenuItem>
      <MenuItem value="שולם בעירייה">שולם בעירייה</MenuItem>
      <MenuItem value="הוק אשראי">הוק אשראי</MenuItem>
      <MenuItem value="הוק בנקאית">הוק בנקאית</MenuItem>
    </Select>

      <Typography variant="h7" color="appPink.main">
        שם הילד
      </Typography>
      <TextField
        label="שם הילד"
        placeholder="שם הילד"
        value={studentText}
        onChange={(e) => setStudentText(e.target.value)}
      />
    </Box>

    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', alignItems: 'center' }}>
      <Typography variant="h7" color="appPink.main">
        תעודת זהות
      </Typography>
      <TextField
        label="תעודת זהות"
        placeholder="תעודת זהות"
        value={studentIdText}
        onChange={(e) => setStudentIdText(e.target.value)}
      />

      <Typography variant="h7" color="appPink.main">
        מספר עסקה
      </Typography>
      <TextField
        label="מספר עסקה"
        placeholder="מספר עסקה"
        value={dealNumberText}
        onChange={(e) => setDealNumberText(e.target.value)}
      />

      <Typography variant="h7" color="appPink.main">
        טווח תאריכים
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="תאריך התחלה"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="תאריך סיום"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <TextField
        type="date"
        value={startText}
        onChange={(e) => setStartText(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        label="תאריך התחלה"
      />

      <TextField
        type="date"
        value={endText}
        onChange={(e) => setEndText(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        label="תאריך סיום"
      />
    </Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '24px', mt: 2 , alignItems: 'center'}}>
      <Button variant="contained" color="primary" type="submit">שלח</Button>
      <Button variant="contained" color="secondary" onClick={handleCancel}>נקה בחירה</Button>
    </Box>
  </Box>
</form>


     <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{
          backgroundColor: 'white',
          border: '2px solid #000',
          padding: '20px',
        }}>
          {/* תוכן ההודעה בחלונית הקופצת */}
          <h2 id="simple-modal-title">שגיאה</h2>
          <p id="simple-modal-description">{errorMessage}</p>
          <Button onClick={handleClose}>סגור</Button>
        </div>
      </Modal>
      {mosads.length > 0 && payments.length === 0 && (
        <Typography mt={10} mb={2} variant="h3" color="appPink.main">
        עכשיו אפשר לבחור גן
      </Typography>
      )}
      {mosads.length > 0 && payments.length > 0 && (
        <DataGrid
          rows={payments}
          columns={columns}
          getRowId={(row) => row.id}
          disableSelectionOnClick
          // onRowClick={({ row }) => {
          //   if (!isSmUp) {
          //     openDetailsDialog();
          //     setSelectedItem(row);
          //   }
          // }}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          sort
          loading={payments === undefined}
          sx={{
            ...dataGridSx,
            width: { xs: "500px", sm: 1 },
            px: { xs: 1.5, sm: 3 },
            py: 1.5,
            ".MuiDataGrid-columnHeaderTitle": {
              color: "appPink.main",
            },
            ".MuiDataGrid-columnHeaders": {
              borderColor: "appPink.main",
            },
            ".MuiDataGrid-cell:focus": {
              outline: "none !important",
            },
          }}
        />
      )}
      
      
      </Container>
        );
      };
      

export default AllPaymentsPage;
