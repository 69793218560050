import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../assets/images/icons/user.svg";
import { Box, Button, Container, Typography } from "@mui/material";
import { useUser } from "../../hooks/useUser";
import { getGreeting } from "../../utils/appConfig";

const Header = () => {
  const { logout, user } = useUser();

  return (
    <Container>
      {!user ? null : (
        <Box
          sx={{
            display: "flex",
            mt: 5,
            mb: 4,
            flexDirection: { xs: "column-reverse", md: "row" },
            justifyContent: { md: "space-between" },
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: { md: "right", xs: "center", sm: "center" },
              margin: { xs: 0 },
            }}
            color="appPurple.main"
            variant="h2"
            mr="auto"
          >
            היי {user.firstName}, { getGreeting() }
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: { xs: "end" },
            }}
          >
            <Button onClick={logout}>התנתק</Button>
            <Button
              LinkComponent={RouterLink}
              startIcon={<UserIcon />}
              color="appBlack"
              to={"my-profile"}
            >
              אזור אישי
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Header;
