import React, { useState } from "react";

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridSearchIcon, GridToolbar } from "@mui/x-data-grid";
import useSWR from "swr";
import { useSnackbar } from "notistack";

import { appFetch, swrFetcher } from "../utils/fetch";
import { useConfirm } from "material-ui-confirm";
import DownloadIcon from "@mui/icons-material/Download";

import { downloadFirestorageFile, getOperationStatus } from "../utils/tools";
import { OPERATION_STATUSES_DB, OPERATION_STATUSES_FRONT } from "../data/lists";

const OperationsPage = () => {
  const [loadingItemId, setLoadingItemId] = useState("");
  const [search, setSearch] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const baseUrl = "/api/operation";

  const { data, mutate } = useSWR(baseUrl, swrFetcher);

  const columns = [
    {
      field: "creditCard",
      headerName: "מספר כרטיס",
      minWidth: 130,
    },
    {
      field: "date",
      headerName: "תאריך",
      valueGetter: ({ value }) => new Date(value).toLocaleDateString("he-il"),
    },
    {
      field: "typeCode",
      headerName: "סוג פעולה",
      minWidth: 50,
    },
    {
      field: "description",
      headerName: "תיאור",
      minWidth: 250,
    },
    {
      field: "providerName",
      headerName: "שם ספק",
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "סכום",
      minWidth: 80,
    },
    {
      field: "currency",
      headerName: "מטבע",
      minWidth: 60,
    },
    {
      field: "status",
      type: "singleSelect",
      editable: true,
      headerName: "סטטוס",
      valueGetter: ({ row }) =>
        row.typeCode !== 722
          ? OPERATION_STATUSES_FRONT.find(
              (i) => i.value === getOperationStatus(row)
            )?.label
          : null,
      valueSetter: ({ value, row }) => {
        const val = OPERATION_STATUSES_FRONT.find(
          (i) => i.label === getOperationStatus(row)
        )?.value;
        return { ...row, status: val };
      },
      valueOptions: OPERATION_STATUSES_DB.map(({ label }) => label),
      minWidth: 80,
    },
    {
      field: "invoiceNote",
      headerName: "הערת משתמש",
      minWidth: 100,
    },
    {
      field: "adminComment",
      headerName: "הערת מנהל",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      type: "actions",
      field: "invoice",
      headerName: "חשבונית",
      sortable: true,
      valueGetter: ({ row }) => (Boolean(row.invoiceFileId) ? 1 : 0),
      getActions: ({ row }) => {
        const downloadInvoiceHandler = async () => {
          try {
            setLoadingItemId(row._id);
            await downloadFirestorageFile(row.invoiceFileId);
          } catch (error) {
            enqueueSnackbar("אירעה שגיאה", { variant: "error" });
          } finally {
            setLoadingItemId("");
          }
        };
        return !row.invoiceFileId
          ? []
          : [
              <Button
                key="download"
                color="success"
                onClick={downloadInvoiceHandler}
                disabled={loadingItemId === row._id}
                startIcon={<DownloadIcon />}
              >
                {row.invoiceUploadDate
                  ? new Date(row.invoiceUploadDate).toLocaleDateString("he")
                  : "--"}
              </Button>,
            ];
      },
    },
  ];

  const onSuccess = (message = "השינויים נשמרו") => {
    enqueueSnackbar(message);
    mutate();
  };
  const onError = (err) => {
    enqueueSnackbar(err.message || "אירעה שגיאה", { variant: "error" });
  };

  async function onCellEditCommit({ id, field, value: cellValue }) {
    try {
      let value = cellValue;
      if (field === "status") {
        value = OPERATION_STATUSES_DB.find((i) => i.label === value)?.value;
      }
      const res = await appFetch(`${baseUrl}/${id}`, {
        method: "PUT",
        body: JSON.stringify({ [field]: value }),
      });
      if (!res.ok) throw new Error();
      onSuccess();
    } catch (err) {
      onError(err);
    }
  }

  return (
    <Box>
      <Box sx={{ height: "80vh", maxWidth: "100%", overflow: "auto" }}>
        <DataGrid
          rows={data || []}
          columns={columns}
          onCellEditCommit={onCellEditCommit}
          getRowId={({ _id }) => _id}
          getRowClassName={(params) =>
            `${params.row.status} ${
              params.row.typeCode === 722 ? "disabled" : ""
            }`
          }
          isCellEditable={(params) => params.row.typeCode !== 722}
          sx={{
            ".MuiDataGrid-row": {
              "&.waiting-for-confirm": {
                bgcolor: "#fff5d9 !important",
                ":hover": {
                  bgcolor: "#fff5d9aa !important",
                },
              },
              "&.confirmed": {
                bgcolor: "#dbffd9 !important",
                ":hover": {
                  bgcolor: "#dbffd9aa !important",
                },
              },
              "&.rejected": {
                bgcolor: "#ffd9d9 !important",
                ":hover": {
                  bgcolor: "#ffd9d9aa !important",
                },
              },
              "&.disabled": {
                bgcolor: "#f1f1f1 !important",
                opacity: 0.7,
                ":hover": {
                  bgcolor: "#f1f1f177 !important",
                },
              },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          loading={!data}
        />
      </Box>
    </Box>
  );
};

export default OperationsPage;
