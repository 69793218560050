import { fireAuth } from "../plugins/firebase.js";

export async function appFetch(url, options = {}) {
  const token = await fireAuth.currentUser.getIdToken();
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
    ...(options.headers || {}),
  };
  if (options.defaultContentType) delete headers["Content-Type"];
  console.log(url)
  return fetch( url, {
    ...options,
    headers,
  });
}

export function swrFetcher(...args) {
  return appFetch(...args).then((res) => res.json());
}
