import React, { useState, } from 'react';
import { Typography } from '@mui/material'; // Adjust the import as needed
import { appFetch } from '../utils/fetch';  // Adjust the path as needed
import { useSnackbar } from "notistack";

const RemindersPage = () => {
  const [phones, setPhones] = useState('');
  const [message, setMessage] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [error, setError] = useState('');
  
  const { enqueueSnackbar } = useSnackbar();
  const source = "0525398789";
  const maxCharLimit = 1005;

  const sendSMS = async () => {
    const phoneArray = phones.split(' ').map(phone => {
      phone = phone.trim();
      if (phone.startsWith('+972')) {
        phone = '0' + phone.slice(4);
      }
      return phone;
    }).filter(phone => phone.startsWith('05') && phone.length === 10);

    if (phoneArray.length !== phones.split(' ').length) {
      setError('חלק מהמספרים אינם תקינים. רק מספרים שמתחילים ב-05 ובאורך 10 ספרות מתקבלים.');
      return;
    }

    let smsMessage = message;
    if (smsMessage === "1") {
      smsMessage = "יש לך חוב במערכת מיי-בילאגן, נא להיכנס למערכת ולשלם את היתרה לתשלום";
    }

    const tag = "הודעה ממערכת מיי-בילאגן";

    const jsonBody = {
      "sms": {
        "source": source,
        "destinations": phoneArray,
        "tag": tag,
        "message":smsMessage,
      }
    };

    try {
        const res = await appFetch('api/smsRoutes/sendSms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonBody)
        });
    
        console.log('Response:', res);
      enqueueSnackbar(res.statusText + '! Message sent successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar('Failed to send message', { variant: 'error' });
    }
  };

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    if (newMessage.length <= maxCharLimit) {
      setMessage(newMessage);
      setCharCount(newMessage.length);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter
      setMessage(prevMessage => prevMessage + "\n");
      setCharCount(prevCount => prevCount + 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendSMS();
  };

  const handlePhonesChange = (e) => {
    const value = e.target.value;
    if (/^[\d\s+]*$/.test(value)) {  // Allow digits, spaces, and plus sign
      setPhones(value);
      setError('');
    } else {
      setError('ניתן להכניס מספרים בלבד.');
    }
  };

  return (
    <>
     <style>
        {`
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100vh;
            background-color: #f5f5f5;
          }

          form {
            width: 100%;
            max-width: 400px;
            padding: 20px;
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            margin: auto;
            text-align: right;
          }

          label {
            display: block;
            margin-bottom: 5px;
          }

          input[type="text"], textarea {
            width: 100%;
            padding: 10px;
            margin-bottom: 20px;
            border: 1px solid #ccc;
            padding-right: 1px;
            border-radius: 4px;
            text-align: right;
            direction: rtl;
          }

          textarea {
            height: 100px;
          }

          button {
            width: 100%;
            padding: 10px;
            background-color: #007bff;
            color: #ffffff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }

          button:hover {
            background-color: #0056b3;
          }

          p {
            margin-top: 10px;
            font-weight: bold;
            text-align: right;
          }

          .error {
            color: red;
            font-size: 0.9em;
            text-align: right;
            direction: rtl;
          }

          .char-count {
            text-align: right;
            direction: rtl;
          }
        `}
      </style>
      <Typography mt={2} mb={2} variant="h3" color="appPink.main">
        שליחת תזכורות
      </Typography>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label><strong>השולח:</strong> חגי חדד {source}</label>
          </div>
          <div className="form-group">
          <label><strong>נמענים:</strong></label>
            <input
              type="text"
              value={phones}
              onChange={handlePhonesChange}
            />
            {error && <div className="error">{error}</div>}
          </div>
          <div className="form-group">
            <label><strong>תוכן ההודעה:</strong></label>
            <textarea
              value={message}
              onChange={handleMessageChange}
              onKeyDown={handleKeyDown}
            />
            <div className="char-count">תווים שנותרו: {maxCharLimit - charCount}</div>
          </div>
          <button type="submit">SMS שלח</button>
        </form>  
      </div>
    </>
  );
};

export default RemindersPage;
