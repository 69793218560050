import React, { useState } from "react";

import { Box, IconButton, LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import useSWR from "swr";
import { useSnackbar } from "notistack";

import { appFetch, swrFetcher } from "../utils/fetch";
import AppFAB from "../components/AppFAB";
import CreateCardDialog from "../components/dialogs/CreateCardDialog";
import { useConfirm } from "material-ui-confirm";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ConnectCardToUserDialog from "../components/dialogs/ConnectCardToUserDialog";
import { dataGridSx } from "../plugins/mui";

const CardsPage = () => {
  const [isOpenCreateCardDialog, setIsOpenCreateCardDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenConnectUserToCardDialog, setIsOpenConnectUserToCardDialog] =
    useState(false);
  const closeConnectUserToCardDialog = () =>
    setIsOpenConnectUserToCardDialog(false);
  const openConnectUserToCardDialog = () =>
    setIsOpenConnectUserToCardDialog(true);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const closeCreateCardDialog = () => setIsOpenCreateCardDialog(false);
  const openCreateCardDialog = () => setIsOpenCreateCardDialog(true);

  const baseUrl = "/api/card";

  const { data, mutate } = useSWR(baseUrl, swrFetcher);
  const columns = [
    {
      field: "number",
      headerName: "מספר כרטיס",
      minWidth: 120,
    },
    {
      field: "issueDate",
      headerName: "תאריך הנפקה",
      minWidth: 80,
      valueGetter: ({ value }) =>
        value ? new Date(value).toLocaleDateString("he-il") : "",
    },
    {
      field: "user",
      headerName: "שייך למשתמש",
      minWidth: 180,
      valueGetter: ({ value }) =>
        value ? `${value.firstName} ${value.lastName}` : "",
    },
    {
      field: "organization",
      headerName: "שייך לארגון",
      minWidth: 180,
    },
    {
      field: "actions",
      type: "actions",
      width: 50 * 2,
      cellClassName: "actions",
      getActions: ({ row }) => [
        <IconButton
          aria-label="add"
          onClick={() => {
            setSelectedItem(row);
            if (!row.user) openConnectUserToCardDialog();
            else deleteCardFromUserHandler(row.user, row);
          }}
          key={`add-btn-${row._id}`}
          color={row.user ? "error" : ""}
        >
          {row.user ? <PersonRemoveIcon /> : <PersonAddIcon />}
        </IconButton>,
        <IconButton
          aria-label="delete"
          onClick={() => {
            deleteItemHandler(row);
          }}
          key={`delete-btn-${row._id}`}
        >
          <DeleteIcon />
        </IconButton>,
      ],
    },
  ];

  const onSuccess = (message = "השינויים נשמרו") => {
    enqueueSnackbar(message);
    mutate();
  };
  const onError = (err) => {
    enqueueSnackbar(err.message || "אירעה שגיאה", { variant: "error" });
  };

  async function deleteCardFromUserHandler(user, card) {
    try {
      await confirm({
        description: `לנתק את כרטיס מספר ${card.number} מ-'${user.firstName} ${user.lastName}'?`,
        confirmationText: "נתק",
      });
      const res = await appFetch(
        `/api/card/${card._id}/detach-from-user/${user._id}`,
        {
          method: "delete",
        }
      );
      if (!res.ok) throw new Error();
      onSuccess();
    } catch (err) {
      onError(err);
    }
  }

  async function deleteItemHandler(item) {
    try {
      await confirm({ description: `למחוק את הכרטיס '${item?.number}'?` });
      const res = await appFetch(`${baseUrl}/${item._id}`, {
        method: "DELETE",
      });
      if (!res.ok) throw new Error();
      onSuccess();
    } catch (err) {
      onError(err);
    }
  }

  async function onCellEditCommit({ id, field, value }) {
    try {
      const res = await appFetch(`${baseUrl}/${id}`, {
        method: "PUT",
        body: JSON.stringify({ [field]: value }),
      });
      if (!res.ok) throw new Error();
      onSuccess();
    } catch (err) {
      onError(err);
    }
  }

  const addItemHandler = () => {
    openCreateCardDialog();
  };
  return (
    <Box sx={{ height: "80vh", maxWidth: "100%", overflow: "auto" }}>
      <DataGrid
        rows={data || []}
        columns={columns}
        onCellEditCommit={onCellEditCommit}
        getRowId={({ _id }) => _id}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sort
        loading={!data}
      />
      <CreateCardDialog
        onClose={closeCreateCardDialog}
        open={isOpenCreateCardDialog}
        onSuccess={() => mutate()}
      />
      <ConnectCardToUserDialog
        card={selectedItem}
        open={isOpenConnectUserToCardDialog}
        onClose={closeConnectUserToCardDialog}
        onSuccess={() => mutate()}
      />
      <AppFAB label="הוסף כרטיס" onClick={addItemHandler} />
    </Box>
  );
};

export default CardsPage;
