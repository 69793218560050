import React, { useEffect, useState, useContext } from 'react';
import {Typography } from "@mui/material";
import { dataGridSx } from "../../plugins/mui";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import { appFetch } from "../../utils/fetch";
import { UserContext } from "../../hooks/useUser";

const AllMosadPage  = () =>  {
  const {mosads, setMosads } = useContext(UserContext);
  console.log(mosads)

  const columns = [
    {
      field: "Name",
      headerName: "שם",
      minWidth: 200,
    },
    {
      field: "field5",
      headerName: "גננת",
      width:  150,
    },
    {
      field: "Email",
      headerName: "אמייל גננת",
      minWidth: 200,
    },
    {
      field: "field6",
      headerName: "טלפון גננת",
      width:  100,
    },
    {
      field: "field11",
      headerName: "סמל מוסד",
      minWidth: 60,
    },
  ];


  return (
    <Container sx={{ height: '100vh' }}>
<Typography mt={10} mb={2} variant="h3" color="appPink.main">
         רשימת מוסדות פעילים
      </Typography>
      <DataGrid
        rows={mosads || []}
        columns={columns}
        getRowId={(row) => row.id}
        disableSelectionOnClick
        // onRowClick={({ row }) => {
        //   if (!isSmUp) {
        //     openDetailsDialog();
        //     setSelectedItem(row);
        //   }
        // }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sort
        loading={mosads === undefined}
        sx={{
          ...dataGridSx,
          width: { xs: "500px", sm: 1 },

          px: { xs: 1.5, sm: 3 },
          py: 1.5,
          ".MuiDataGrid-columnHeaderTitle": {
            color: "appPink.main",
          },
          ".MuiDataGrid-columnHeaders": {
            borderColor: "appPink.main",
          },
          ".MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          '.MuiDataGrid-cell[data-field="amount"] .MuiDataGrid-cellContent': {
            direction: "rtl",
          },
        }}
      />
</Container>
  );
};

export default AllMosadPage;
