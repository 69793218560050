import {
  AppBar,
  Autocomplete,
  Button,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { appFetch, swrFetcher } from "../../utils/fetch";
import { useSnackbar } from "notistack";
import useSWR from "swr";

function ConnectCardToUserDialog({
  open,
  onClose,
  onSuccess,
  user: fixedUser,
  card: fixedCard,
}) {
  const [card, setCard] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { data: cards, mutate: mutateCards } = useSWR("/api/card", swrFetcher);
  const { data: users } = useSWR("/api/user", swrFetcher);
  const unusedCards = (cards || []).filter(({ user }) => !user);

  useEffect(() => {
    open && mutateCards();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const clearForm = () => {
    setCard(null);
    setSelectedUser(null);
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      setLoading(true);
      const res = await appFetch(
        `/api/card/${(fixedCard || card)?._id}/attach-to-user/${
          (fixedUser || selectedUser)?._id
        }`,
        { method: "POST" }
      );
      if (!res.ok) throw new Error();
      enqueueSnackbar();
      onSuccess();
      onClose();
      clearForm();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("אירעה שגיאה", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            color={"white"}
            component="div"
          >
            חיבור כרטיס למשתמש
          </Typography>
        </Toolbar>
      </AppBar>
      <form onSubmit={onSubmit}>
        <Stack p={3} spacing={1.5} display="flex" alignItems="center">
          <Autocomplete
            options={users || []}
            sx={{ width: 300 }}
            value={fixedUser || selectedUser}
            disabled={Boolean(fixedUser)}
            renderInput={(params) => <TextField {...params} label="משתמש" />}
            onChange={(ev, newValue) => setSelectedUser(newValue)}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName} (${option.email})`
            }
            id="_id"
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
          <Autocomplete
            options={unusedCards}
            sx={{ width: 300 }}
            value={fixedCard || card}
            disabled={Boolean(fixedCard)}
            renderInput={(params) => <TextField {...params} label="כרטיס" />}
            onChange={(ev, newValue) => setCard(newValue)}
            getOptionLabel={(option) => option.number}
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            sx={{ mt: 3 }}
          >
            חבר כרטיס למשתמש
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
}

export default ConnectCardToUserDialog;
