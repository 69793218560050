// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig =
  process.env.REACT_APP_ENV_MODE === "test" ||
  process.env.NODE_ENV === "development"
    ? {
        apiKey: "AIzaSyAwin47nuW-fypmwL5mAp1b-B0HdnhQe60",
        authDomain: "cashbox-test-3ee11.appspot.com",
        projectId: "cashbox-test-3ee11",
        storageBucket: "cashbox-test-3ee11.appspot.com",
        messagingSenderId: "583203300574",
        appId: "1:583203300574:web:9a31e67a3a7364fddc0e4f",
      }
    : {
        apiKey: "AIzaSyAuHp5TILQDSLCttF2lHUlY6IV8Lizu1JE",
        authDomain: "cashbox-4f125.firebaseapp.com",
        projectId: "cashbox-4f125",
        storageBucket: "cashbox-4f125.appspot.com",
        messagingSenderId: "837439198238",
        appId: "1:837439198238:web:9883438912e5a5ceb90a0f",
        measurementId: "G-9YY0J2KL96",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const fireAuth = getAuth(app);
