import { Box, Card, Chip, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import useSWR from "swr";
import OperationsTable from "../components/tables/OperationsTable";
import { swrFetcher } from "../utils/fetch";
import { getOperationStatus, getPriceString } from "../utils/tools";

function DashboardPage() {
  const { data: myCards } = useSWR("/api/card/user", swrFetcher);

  const { data: myOperations, mutate } = useSWR(
    "/api/operation/user",
    swrFetcher
  );

  // שורת הבעיה עם mutate נוסף
   const { data: lastOperation, mutate: mutateLastOperation } = useSWR(
  "/api/operation/last",
  swrFetcher
);

  const [operationStatus, setOperationStatus] = useState("confirmed");
  const [totalBalance, setTotalBalance] = useState(0);
  const [latestOperationDate, setLatestOperationDate] = useState("");


// console.log(myCards)
// let newObj = {
//   balance: 20000,
//   holderName: "John Doe",
//   number: "1234567890",
//   organization: "XYZ Corp",
//   user: "some_user_id",
//   __v: 0,
//   _id: "some_id"
// };

// // Pushing the new object into the array
// myCards.push(newObj);

// // Now arrayOfObjects contains the new object
// console.log(myCards);

  // let totalfees = 0;
  const feesOperations = [];
  const groupedOperationsByStatus = {
    waiting: [],
    "waiting-for-confirm": [],
    confirmed: [],
    rejected: [],
  };
  myOperations?.forEach((item) => {
    if (item.typeCode === 722) {
      // totalfees += item.amount;
      feesOperations.push(item);
    } else {
      const operationStatus = getOperationStatus(item);
      groupedOperationsByStatus[operationStatus].push(item);
    }
  });

  useEffect(() => {
    if (myCards) {
      let total = 0;
      myCards.forEach((item) => {
        total += item.balance;
      });
      setTotalBalance(total);
    }
  }, [myCards]);

  useEffect(() => {
    if (myOperations) {
      const latestDate = getLatestOperationDate(lastOperation);
      setLatestOperationDate(latestDate);
    }
  }, [myOperations]);


  const getOperationCount = (status) =>
    groupedOperationsByStatus[status].length;
    

  const getLatestOperationDate = (operation) => {
    if (!operation || !operation.date) return "";
    const date = new Date(operation.createdAt);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
    

  return (
    <Container>
      <Box
        display={"flex"}
        sx={{ flexWrap: "wrap", fontSize: { xs: "8px" } }}
        justifyContent="center"
        gap={1.5}
      >
        <InsightCard
          color={"appPink.main"}
          label="עסקאות לטיפול"
          value={getOperationCount("waiting")}
        />
        <InsightCard
          color={"appGreen.main"}
          label="עסקאות אושרו"
          value={getOperationCount("confirmed")}
        />
        <InsightCard
          color={"appYellow.main"}
          label="עסקאות בהמתנה"
          value={getOperationCount("waiting-for-confirm")}
        />
        <InsightCard
          color={"appBlack.main"}
          label="עסקאות נדחו"
          value={getOperationCount("rejected")}
        />
        <InsightCard
          color={"appPink.main"}
          label="יתרה"
          //value={`${ ( myCards && myCards.length ) ? getPriceString(myCards[0]?.balance) : "-"} ₪`}
           value={`${ ( myCards && myCards.length ) ? getPriceString(totalBalance) : "-"} ₪`}
        />
      </Box>

      {latestOperationDate && (
        <Typography mt={10} mb={2} variant="h4" color="appPink.main">
          הנתונים מעודכנים ל {latestOperationDate}
        </Typography>
      )}

      <Typography mt={10} mb={2} variant="h3" color="appPink.main">
        עסקאות לטיפול
      </Typography>
      <OperationsTable
        data={groupedOperationsByStatus["waiting"]}
        mutate={mutate}
      />
      <Typography mt={6} mb={2} variant="h3" color="appPink.main">
        תנועות
      </Typography>
      <Box
        display={"flex"}
        alignItems={"center"}
        p={0.5}
        gap={0.5}
        // mb={1.5}
        sx={{
          border: "1px solid #eee",
          borderRadius: 20,
          width: "fit-content",
          margin: 1.5,
        }}
      >
        <StatusChip
          onClick={() => setOperationStatus("confirmed")}
          label={`אושר (${getOperationCount("confirmed")})`}
          isActive={operationStatus === "confirmed"}
        />
        <StatusChip
          onClick={() => setOperationStatus("waiting-for-confirm")}
          label={`בהמתנה (${getOperationCount("waiting-for-confirm")})`}
          isActive={operationStatus === "waiting-for-confirm"}
        />
        <StatusChip
          onClick={() => setOperationStatus("rejected")}
          label={`נדחה (${getOperationCount("rejected")})`}
          isActive={operationStatus === "rejected"}
        />
      </Box>
      <OperationsTable
        data={groupedOperationsByStatus[operationStatus]}
        mutate={mutate}
      />
    </Container>
  );
}

export function InsightCard({ value, color, label }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "6rem",
        textAlign: "center",
        width: { xs: "30%", md: "19%" },
        padding: { xs: "10px" },

        // minWidth:{md:"19%"}
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: { xs: "1.4rem", md: "2.5rem" } }}
        color={color}
      >
        {value}
      </Typography>
      <Typography
        margin={"5px"}
        sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
        variant="h4"
      >
        {label}
      </Typography>
    </Card>
  );
}

function StatusChip({ label, isActive, onClick }) {
  return (
    <Chip
      size="medium"
      label={label}
      color={isActive ? "appGreen" : "disabledText"}
      variant={isActive ? "filled" : "outlined"}
      onClick={onClick}
      sx={{ width: "5.5rem", border: "none" }}
    />
  );
}

export default DashboardPage;
