import React, { useEffect, useState, useContext } from 'react';
import { Typography, InputLabel, Select, MenuItem, FormControl, Card, Box, Modal, Button } from "@mui/material";
import { dataGridSx } from "../../plugins/mui";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import { appFetch } from "../../utils/fetch";
import { orderBy } from 'lodash';
import { UserContext } from "../../hooks/useUser";
import  LoadingScreen from "../../components/LoadingScreen";
import TextField from '@mui/material/TextField';


// ייבוא רכיבי דיאלוג
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


const AllCoordinatorStudentsPage = () => {
  const [students, setStudents] = useState([]);
  const [newStudents, setNewStudents] = useState([]);
  const [noStudentsMessage, setNoStudentsMessage] = useState('');
  const [mosadName, setMosadName] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedChildName, setSelectedChildName] = useState(null);
  const [selectedChildId, setSelectedChildId] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [confirmShibutzDialogOpen, setConfirmShibutzDialogOpen] = useState(false);
  const [selectedMosad, setSelectedMosad] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [errors, setErrors] = useState({}); 
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
const [proceedToCharge, setProceedToCharge] = useState(false);
const [showAmountField, setShowAmountField] = useState(false);
const [chargeAmount, setChargeAmount] = useState('');


const {  mosads,  filterdMosads } = useContext(UserContext);

  const MosadNameCellRenderer = ({ value }) => {
    return <div>{value.name}</div>; 
  };

 

  const columns = [
    {
      field: "contactid",
      headerName: "שם",
      minWidth: 120,
      renderCell: (params) => <MosadNameCellRenderer value={params.value} />, // Use the custom cell renderer
    },
    {
      field: "id_child",
      headerName: "תעודת זהות",
      minWidth: 100,
    },
    {
      field: "field24",
      headerName: "רגשיות למזון",
      minWidth: 100,
      renderCell: (params) => (params.value ? params.value : 'אין'),
    },
    {
      field: "field34",
      headerName: "העדפות מזון",
      minWidth: 120,
    },    
    {
      field: "field25",
      headerName: "הזנה",
      minWidth: 60,
      renderCell: (params) => (params.value ? params.value : 'לא'),
    },
      {
        field: "actions",
        headerName: "שינוי שיבוץ",
        width: 120,
        renderCell: (params) => (
            <Button
                variant="contained"
                color="primary"
                sx={{
                    backgroundColor: '#4caf50', // צבע רקע ירוק כהה
                    color: 'white', // צבע טקסט לבן
                    '&:hover': {
                        backgroundColor: '#388e3c', // צבע רקע כהה יותר ב-hover
                    },
                    borderRadius: '8px', // פינות מעוגלות
                    padding: '5px 10px', // ריווח פנימי
                    textTransform: 'none', // ביטול אותיות גדולות אוטומטית
                    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)', // הוספת צל
                }}
                onClick={() => handleOpenDialog(params)}
            >
                שינוי שיבוץ
            </Button>
        ),
    },
    {
      field: "reportIssue",
      headerName: "דווח שיבוץ לא נכון",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          sx={{
            borderRadius: '8px',
            padding: '5px 10px',
            textTransform: 'none',
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          }}
          onClick={() => handleReportIssue(params.row)}
        >
          דווח שיבוץ לא נכון
        </Button>
      ),
    },
    {
      field: "duplicatePlacement",
      headerName: "שיבוץ כפול",
      width: 130,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="warning"
          sx={{
            borderRadius: '8px',
            padding: '5px 10px',
            textTransform: 'none',
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          }}
          onClick={() => handleDuplicatePlacement(params.row)}
        >
          שיבוץ כפול
        </Button>
      ),
    },
    {
      field: "cancelRegistration",
      headerName: "ביטול רישום",
      width: 130,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          sx={{
            borderRadius: '8px',
            padding: '5px 10px',
            textTransform: 'none',
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
          }}
          onClick={() => handleCancelRegistration(params.row)}
        >
          ביטול רישום
        </Button>
      ),
    },
  ];

  const handleShibutz = () => {
    setConfirmShibutzDialogOpen(true); // פותח את דיאלוג האישור
  };
  

  const handleConfirmShibutz = async () => {
    setInputValue('');
    setOpenDialog(false);
    setConfirmShibutzDialogOpen(false); // סוגר את דיאלוג האישור
  
    console.log(`שיבוץ חדש לילד ${selectedChildName}. במוסד: ${selectedMosad.Name}`);
    try {
        const updateResponse = await appFetch(`api/zohoMosads/changeSchedulingById/${selectedChildId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(selectedMosad) // שלח את הנתונים שהתקבלו כ payload
        });

        if (!updateResponse.ok) {
          setErrorMessage("השיבוץ נכשל, נסה שנית מאוחר יותר או פנה לשרות לקוחות");
            throw new Error('Failed to update mosad scheduling');
        }

        console.log('Mosad scheduling updated successfully');
        handleOpenDialogWithMessage (" שינוי השיבוץ נשלח למערכת. כשהשינוי יתעדכן, הטבלה תרענן נתונים.");

        // התחלת בדיקה כל 2 שניות עד שיהיה שינוי במספר התלמידים
        startCheckingForUpdates();

    } catch (error) {
        setOpenErrorDialog(true);
    }
};


const checkStudentCountUpdate = async () => {
  try {
      const res = await appFetch(`api/zohoMosads/SchedulingByMosadName/${mosadName}`, {
          method: 'GET',
      });

      if (!res.ok) {
          throw new Error('Failed to fetch students');
      }

      const data = await res.json();

      // בדיקה אם מספר התלמידים השתנה
      if (data && students.length !== data.data.length) {
          console.log('Student count updated, refreshing the table');
          
          // עדכון הרשימה אם יש שינוי במספר התלמידים
          setStudents(data.data);

          // רענון נוסף אם יש צורך לרענן את הדף כולו
          setNewStudents(prevState => !prevState);

          // מחזיר true כדי לציין שהתרחש שינוי במספר התלמידים
          return true;
      } else {
          console.log('No change in student count');
          return false;
      }
  } catch (error) {
      console.error('Error checking student count:', error);
      return false; // מחזיר false במקרה של שגיאה
  }
};

const startCheckingForUpdates = () => {
  let hasUpdated = false; // משתנה בוליאני לשמירה אם היה עדכון

  const intervalId = setInterval(async () => {
    const updateStatus = await checkStudentCountUpdate();

    if (updateStatus) {
      hasUpdated = true; // מציין שהיה עדכון
      clearInterval(intervalId); // עצירת הבדיקות
    }
  }, 6000);

  // אחרי דקה (60 שניות) מפסיקים את הבדיקה אם לא היה עדכון
  setTimeout(() => {
    clearInterval(intervalId);

    if (!hasUpdated) { // הצגת ההודעה רק אם לא היה עדכון
      alert('המערכת לא מצליחה לעדכן כרגע נתונים, העדכון יתבצע כשהשרת יתוקן. תודה');
    }
  }, 60000); // 60 שניות
};

const handleReportIssue = async (row) => {
  const selectedChildId = row.id; // ניתן לשנות לפי השדה המתאים לך
  const payload = {
    message: 'הרכז דיווח שהוא לא מכיר את התלמיד',
    status : 'דווח כשיבוץ לא נכון'
  };
  try {
    const updateResponse = await appFetch(`api/zohoMosads/reportedOnScheduling/${selectedChildId}`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
         body: JSON.stringify(payload)
    });

    if (!updateResponse.ok) {
      setErrorMessage("הדיווח נכשל, נסה שנית מאוחר יותר או פנה לשרות לקוחות");
      throw new Error('Failed to report scheduling');
    }

    console.log('Reported scheduling issue successfully');
    handleOpenDialogWithMessage ("הדיווח התקבל בהצלחה");

    
  } catch (error) {
    setOpenErrorDialog(true);
    console.error("Error reporting issue:", error);
  }
};

const handleDuplicatePlacement = async (row) => {
  const selectedChildId = row.id; // ניתן לשנות לפי השדה המתאים לך
  
  try {
    const updateResponse = await appFetch(`api/zohoMosads/duplicatePlacement/${selectedChildId}`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message: 'שיבוץ כפול',
      })
    });

    if (!updateResponse.ok) {
      setErrorMessage("שינוי סטטוס נכשל, נסה שנית מאוחר יותר או פנה לשרות לקוחות");
      throw new Error('Failed to report duplicate placement');
    }

    console.log('Duplicate placement reported successfully');
    handleOpenDialogWithMessage("סטטוס התלמיד שונה בהצלחה");

  } catch (error) {
    setOpenErrorDialog(true);
    console.error("Error reporting duplicate placement:", error);
  }
};
const handleCancelRegistration = (row) => {
  const selectedChildId = row.id; 
  setSelectedChildName(row.contactid.name);
  setSelectedChildId(selectedChildId);
  setCancelDialogOpen(true); // פותח את הדיאלוג הראשון
};

const handleSubmitCancellation = async () => {
  const payload = {
    message: 'ממתין לביטול',
    chargeAmount: chargeAmount || 0 // אם המשתמש הכניס סכום
  };

  try {
    const updateResponse = await appFetch(`api/zohoMosads/cancelRegistration/${selectedChildId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (!updateResponse.ok) {
      setErrorMessage("ביטול הרישום נכשל, נסה שנית מאוחר יותר או פנה לשרות לקוחות");
      throw new Error('Failed to cancel registration');
    }

    console.log('Registration canceled successfully');
    handleOpenDialogWithMessage("הרישום בוטל בהצלחה");

  } catch (error) {
    setOpenErrorDialog(true);
    console.error("Error canceling registration:", error);
  }
  handleCancelDialogClose();
};

const handleCancelDialogClose = () => {
  setCancelDialogOpen(false);  // סוגר את הדיאלוג הראשון
  setProceedToCharge(false);   // סוגר את הדיאלוג השני (חיוב)
  setShowAmountField(false);   // סוגר את הדיאלוג להזנת סכום
  setChargeAmount('');         // מאפס את תיבת הסכום
};


  
const handleOpenDialog = (params) => {
    setSelectedChildName(params.row.contactid.name);
    setSelectedChildId(params.id);
    setOpenDialog(true);
};

const handleCloseDialog = () => {
    setOpenDialog(false);
    setInputValue('');
};

const handleOpenDialogWithMessage = (message) => {
  setDialogMessage(message);  // העברת ההודעה הדינמית
  setMessageDialogOpen(true); // פתיחת הדיאלוג
};

const handleDialogClose = () => {
  setMessageDialogOpen(false);
  setDialogMessage(""); // איפוס ההודעה
}; 

useEffect(() => {
  (async () => {
    try {
      if (!mosadName) {
        return; 
      }
      setLoading(true);
      const res = await appFetch(`api/zohoMosads/SchedulingByMosadName/${mosadName}`, {
          method: 'GET',
      });
      if (!res.ok) {
          throw new Error('Failed to fetch students');
      }
      const data = await res.json();

      if (!data) {
          setNoStudentsMessage('אין ילדים משובצים בגן שבחרת');
          setStudents(0);
      } else {
          setStudents(data.data); // עדכון אם יש נתונים
          setNoStudentsMessage(''); // מנקה את ההודעה אם יש ילדים
      }
      
    } catch (error) {
      console.error('Error fetching students:', error);
      setNoStudentsMessage('שגיאה בטעינת הנתונים');
    } finally {
      setLoading(false); // סיום טעינה
    }
  })();
}, [mosadName, newStudents]); // ה-useEffect יפעל כאשר mosadName או newStudents משתנים

  
  
  const handleChange = (event) => {
    const selectedId = event.target.value;
    // חפש את המוסד מתוך רשימת mosads
    const mosad = mosads.find(m => m.id === selectedId);
    setSelectedMosad(mosad);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const [openStudentReportFormDialog, setOpenStudentReportFormDialog] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [motherName, setMotherName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const handleOpenStudentReportFormDialog = () => {
    setOpenStudentReportFormDialog(true);
  };

  const handleCloseStudentReportFormDialog = () => {
    // ניקוי של כל שדות הטופס
    setStudentName('');
    setIdNumber('');
    setFatherName('');
    setMotherName('');
    setContactPhone('');
    setContactEmail('');
  
    // סגירת הדיאלוג
    setOpenStudentReportFormDialog(false);
  };

  const validateFields = () => {
    const newErrors = {};
  
    if (!studentName) {
      newErrors.studentName = "שדה זה נדרש";
    }
  
    if (!idNumber) {
      newErrors.idNumber = "שדה זה נדרש";
    } else if (idNumber.length !== 9) {
      newErrors.idNumber = "תעודת זהות צריכה להכיל 9 ספרות";
    }
  
    if (!contactPhone) {
      newErrors.contactPhone = "שדה זה נדרש";
    } else if (!/^\d+$/.test(contactPhone)) {
      newErrors.contactPhone = "מספר טלפון לא תקין";
    }
  
    if (contactEmail && !/\S+@\S+\.\S+/.test(contactEmail)) {
      newErrors.contactEmail = "כתובת מייל לא תקינה";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // true אם אין שגיאות
  };
  

  const handleSubmitStudentReportFormReport = async () => {
    if (!validateFields()) {
      return;
    }
  
    const reportData = {
      studentName,
      idNumber,
      fatherName,
      motherName,
      contactPhone,
      contactEmail,
    };
  
    console.log('Report Data:', reportData);
  
    try {
      const response = await appFetch('https://hook.eu1.make.com/uw7r2zvioi5vhrcy38uiht3rtyifmx8k', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit report');
      }
  
      console.log('Report submitted successfully');
      handleCloseStudentReportFormDialog();
    } catch (error) {
      console.error('Error submitting report:', error);
    }
  };



  return (
    <Container sx={{ height: '100vh' }}>
       <Typography mb={2} variant="h3" color="appPink.main">
        רשימות תלמידים
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
      <Box sx={{ display: 'flex', gap: '16px' }}>
  <FormControl sx={{ minWidth: '400px' }}> {/* הגדלת רוחב ה-FormControl */}
    <Select
      value={selectedOption}
      onChange={(e) => {
        setSelectedOption(e.target.value);
        setMosadName(e.target.value); // עדכון mosadName לפי הבחירה
      }}
      displayEmpty
      sx={{ width: '300px' }} // הגדרת רוחב ה-Select ל-300px או כל גודל אחר שתרצה
    >
      <MenuItem value="" disabled>בחר מוסד</MenuItem>
      {filterdMosads.map((mosad) => (
        <MenuItem key={mosad.id} value={mosad.Name}>{mosad.Name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Box>
</Box>
<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{
          backgroundColor: 'white',
          border: '2px solid #000',
          padding: '20px',
        }}>
          {/* תוכן ההודעה בחלונית הקופצת */}
          <h2 id="simple-modal-title">שגיאה</h2>
          <p id="simple-modal-description">{errorMessage}</p>
          <Button onClick={handleClose}>סגור</Button>
        </div>
      </Modal>



      <div>
      {loading ? (
        <LoadingScreen /> // מציג את מסך הטעינה בזמן טעינה
      ) : (
        <>
          {mosads.length === 0 &&(
            <Typography mt={10} mb={2} variant="h3" align="center" color="appPink.main">
              אנא המתן בזמן שהמערכת מאתרת  נתונים
            </Typography>
          )}
        </>
      )}
    </div>
    {mosads.length > 0 && students.length > 0 && (
  <>
  <Box
        display={"flex"}
        sx={{ flexWrap: "wrap", fontSize: { xs: "8px" } }}
        justifyContent="center"
        gap={1.5}
      >
        <InsightCard
          color={"appPink.main"}
          label="ילדים בגן"
          value={students.length}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={3}>
        <Typography variant="h4" color="appPink.main">
          רשימת תלמידים עדכנית ב{mosadName}:
        </Typography>
        <Button
          variant="outlined"
          color="error"
          onClick={handleOpenStudentReportFormDialog}
        >
          דיווח על תלמיד שלא קיים
        </Button>
      </Box>
    <DataGrid
      rows={students}
      columns={columns}
      getRowId={(row) => row.id}
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: "createdAt", sort: "desc" }],
        },
      }}
      loading={students === undefined}
      sx={{
        ...dataGridSx,
        width: { xs: "500px", sm: 1 },
        px: { xs: 1.5, sm: 3 },
        py: 1.5,
        ".MuiDataGrid-columnHeaderTitle": {
          color: "appPink.main",
        },
        ".MuiDataGrid-columnHeaders": {
          borderColor: "appPink.main",
        },
        ".MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
      }}
    />
  </>
)}
{mosadName !=='' && (
     <Typography variant="h2" mt={10} align="center" color="error">
     {noStudentsMessage}
   </Typography>
)}
<Dialog
  open={openDialog}
  onClose={handleCloseDialog}
  maxWidth="md"
  fullWidth
  sx={{
    '& .MuiDialog-paper': {
      width: '600px',
      maxWidth: 'none',
    },
  }}
>
  <DialogTitle style={{ textAlign: 'center' }}>
    שינוי שיבוץ של הילד {selectedChildName}
  </DialogTitle>
   <DialogContent>
      <FormControl fullWidth margin="dense">
        <InputLabel id="mosad-select-label">שם המוסד שאליו תרצה לשבץ את הילד</InputLabel>
        <Select
          labelId="mosad-select-label"
          id="mosad-select"
          value={selectedMosad.Name}
          onChange={handleChange}
          label="שם המוסד שאליו תרצה לשבץ את הילד"
          autoFocus
        >
          {mosads.map((mosad) => (
            <MenuItem key={mosad.id} value={mosad.id}>
              {mosad.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </DialogContent>
  <DialogActions style={{ justifyContent: 'space-between' }}>
    <Button onClick={handleCloseDialog} color="primary">
      חזור
    </Button>
    <Button onClick={handleShibutz} color="primary">
      שבץ
    </Button>
  </DialogActions>
</Dialog>

<Dialog
  open={confirmShibutzDialogOpen}
  onClose={() => setConfirmShibutzDialogOpen(false)}
  maxWidth="sm"
  fullWidth
>
  <DialogTitle style={{ textAlign: 'center' }}>
    אישור שיבוץ
  </DialogTitle>
  <DialogContent>
    <Typography variant="body1">
      האם אתה מאשר לשבץ את הילד {selectedChildName} בגן {selectedMosad.Name}?
    </Typography>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'space-between' }}>
    <Button
      onClick={() => setConfirmShibutzDialogOpen(false)} // סוגר את הדיאלוג וחוזר לתוכן הקודם
      color="error"
    >
      ביטול
    </Button>
    <Button
      onClick={handleConfirmShibutz} // מפעיל את handleConfirmShibutz לאחר אישור
      color="primary"
    >
      כן, אני מאשר
    </Button>
  </DialogActions>
</Dialog>


        <Dialog
      open={openErrorDialog}
      onClose={() => setOpenErrorDialog(false)}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <DialogTitle id="error-dialog-title" sx={{ textAlign: "center" }}>
        שגיאה
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="error" sx={{ textAlign: "center" }}>
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={() => setOpenErrorDialog(false)} color="primary">
          סגור
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={messageDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>עדכון שיבוץ</DialogTitle>
        <DialogContent>
        {dialogMessage}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            אישור
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={cancelDialogOpen} onClose={handleCancelDialogClose}>
  <DialogTitle>ביטול רישום</DialogTitle>
  <DialogContent>
    האם אתה בטוח שאתה רוצה לבטל את הרישום?
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setProceedToCharge(true)} color="primary">
      כן
    </Button>
    <Button onClick={handleCancelDialogClose} color="secondary">
      לא
    </Button>
  </DialogActions>
</Dialog>

<Dialog open={proceedToCharge} onClose={handleCancelDialogClose}>
  <DialogTitle>חיוב</DialogTitle>
  <DialogContent>
    האם צריך לחייב את {selectedChildName} סכום מסוים?
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setShowAmountField(true)} color="primary">
      יש צורך
    </Button>
    <Button onClick={handleSubmitCancellation} color="secondary">
      אין צורך
    </Button>
  </DialogActions>
</Dialog>

{showAmountField && (
  <Dialog 
    open={showAmountField} 
    onClose={handleCancelDialogClose}
    sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: 'none' } }} // כאן אתה מגדיר רוחב מותאם אישית
  >
    <DialogTitle>הזן סכום</DialogTitle>
    <DialogContent>
      <TextField
        label="סכום לחיוב"
        value={chargeAmount}
        onChange={(e) => setChargeAmount(e.target.value)}
        fullWidth // כך תוודא שהתיבה מתרחבת עם הדיאלוג
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleSubmitCancellation} color="primary">
        שלח
      </Button>
      <Button onClick={handleCancelDialogClose} color="secondary">
        בטל
      </Button>
    </DialogActions>
  </Dialog>
)}

 {/* דיאלוג */}
 <Dialog open={openStudentReportFormDialog} onClose={handleCloseStudentReportFormDialog}>
    <DialogTitle>דווח על תלמיד שלא קיים</DialogTitle>
    <DialogContent>
      <TextField
        label="שם התלמיד"
        fullWidth
        value={studentName}
        onChange={(e) => setStudentName(e.target.value)}
        margin="dense"
        required
        error={!!errors.studentName}
        helperText={errors.studentName}
      />
      <TextField
        label="תעודת זהות"
        fullWidth
        value={idNumber}
        onChange={(e) => setIdNumber(e.target.value)}
        margin="dense"
        required
        error={!!errors.idNumber}
        helperText={errors.idNumber}
      />
      <TextField
        label="שם האב"
        fullWidth
        value={fatherName}
        onChange={(e) => setFatherName(e.target.value)}
        margin="dense"
      />
      <TextField
        label="שם האם"
        fullWidth
        value={motherName}
        onChange={(e) => setMotherName(e.target.value)}
        margin="dense"
      />
      <TextField
        label="טלפון ליצירת קשר"
        fullWidth
        value={contactPhone}
        onChange={(e) => setContactPhone(e.target.value)}
        margin="dense"
        required
        error={!!errors.contactPhone}
        helperText={errors.contactPhone}
      />
      <TextField
        label="אימייל ליצירת קשר"
        fullWidth
        type="email"
        value={contactEmail}
        onChange={(e) => setContactEmail(e.target.value)}
        margin="dense"
        error={!!errors.contactEmail}
        helperText={errors.contactEmail}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseStudentReportFormDialog} color="secondary">
        בטל
      </Button>
      <Button onClick={handleSubmitStudentReportFormReport} color="primary">
        שלח
      </Button>
    </DialogActions>
  </Dialog>
</Container>
  );
};

export default AllCoordinatorStudentsPage;

export function InsightCard({ value, color, label }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "6rem",
        textAlign: "center",
        width: { xs: "30%", md: "19%" },
        padding: { xs: "10px" },
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: { xs: "1.4rem", md: "2.5rem" } }}
        color={color}
      >
        {value}
      </Typography>
      <Typography
        margin={"5px"}
        sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
        variant="h4"
      >
        {label}
      </Typography>
    </Card>
  );
}
