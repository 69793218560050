import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";
import { ThemeProviderCustom } from "./plugins/mui";
import { UserProvider } from "./hooks/useUser";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProviderCustom>
      <CssBaseline />
      <ConfirmProvider
        defaultOptions={{
          title: "האם אתה בטוח?",
          confirmationText: "מחק",
          cancellationText: "ביטול",
          confirmationButtonProps: {
            variant: "contained",
            color: "error",
          },
          cancellationButtonProps: {
            color: "appBlack",
          },
        }}
      >
        <SnackbarProvider
          autoHideDuration={3000}
          iconVariant={{ error: "" }}
          variant="success"
          title="הפעולה בוצעה"
          disableWindowBlurListener
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          style={{ direction: "rtl" }}
        >
          <UserProvider>
            <App />
          </UserProvider>
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProviderCustom>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
