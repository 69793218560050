import React, { useEffect, useState, useContext } from 'react';
import { Typography, InputLabel, Select, MenuItem, FormControl, TextField, Box,  Modal ,Button} from "@mui/material";
import { dataGridSx } from "../../plugins/mui";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import { appFetch } from "../../utils/fetch";
import { orderBy } from 'lodash';
import { UserContext } from "../../hooks/useUser";

const AllStudentsPage = () => {
  const [students, setStudents] = useState([]);
  const {mosads, setMosads } = useContext(UserContext);
  const [mosadName, setMosadName] = useState('');
  const [teacherName, setTeacherName] = useState('');
  const [semelMosad, setSemelMosad] = useState('');
  const [teacherText, setTeacherText] = useState('');
  const [semelText, setSemelText] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);

  const MosadNameCellRenderer = ({ value }) => {
    return <div>{value.name}</div>; 
  };

 

  const columns = [
    {
      field: "contactid",
      headerName: "שם",
      minWidth: 150,
      renderCell: (params) => <MosadNameCellRenderer value={params.value} />, // Use the custom cell renderer
    },
    {
        field: 'mosad',
        headerName: 'גן',
        width: 150,
        renderCell: (params) => <MosadNameCellRenderer value={params.value} />, // Use the custom cell renderer
      },
    {
      field: "id_child",
      headerName: "תעודת זהות",
      minWidth: 150,
    },
    {
      field: "total_amount",
      headerName: "סכום לתשלום",
      width:  120,
    },
    {
      field: "payed",
      headerName: "שולם",
      width:  120,
    },
    {
      field: "debt",
      headerName: "יתרה",
      width: 120,
      renderCell: (params) => {
        let color = 'red';
        return <div style={{ color }}>{params.value}</div>;
      }
    }
    ,    
    {
      field: "status",
      headerName: "סטטוס",
      width:  150,
    },
    {
        field: "Last_Activity_Time",
        headerName: "תאריך כניסה אחרון",
        width:  300,
        renderCell: (params) => {
          const dateTimeString = params.value;
          const dateOnly = dateTimeString.split("T")[0];
          return <div>{dateOnly}</div>;
        }
      },
   
  ];
  

  useEffect(() => {
    (async () => {
      try {
        if (!mosadName) {
          return; 
        }
        const res = await appFetch(`api/zohoMosads/SchedulingByMosadName/${mosadName}`, {
          method: 'GET',
        });
        if (!res.ok) {
          throw new Error('Failed to fetch students');
        }
        const data = await res.json();
        setStudents(data.data);
        console.log(data.data)
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    })();
  }, [mosadName]);

  useEffect(() => {
    (async () => {
      try {
        if (!teacherName) {
          return; 
        }
        const res = await appFetch(`api/zohoMosads/SchedulingByTeacherName/${teacherName}`, {
          method: 'GET',
        });
        if (!res.ok) {
          const errorData = await res.json();
          console.error('Error fetching students:', errorData.message);
          setErrorMessage(errorData.message);
          setOpen(true);
          return;
        }
        const data = await res.json();
        setStudents(data.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    })();
  }, [teacherName]);

  useEffect(() => {
    (async () => {
      try {
        if (!semelMosad) {
          return; 
        }
        const res = await appFetch(`api/zohoMosads/SchedulingBySemelMosad/${semelMosad}`, {
          method: 'GET',
        });
        if (!res.ok) {
          const errorData = await res.json();
          console.error('Error fetching students:', errorData.message);
          setErrorMessage(errorData.message);
          setOpen(true);
          return;
        }
        const data = await res.json();
        setStudents(data.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    })();
  }, [semelMosad]);

  


  const handleSubmit = (e) => {
    e.preventDefault(); // מונע מהטופס להישלח באופן רגיל
    
    // בודק אם המשתמש מילא יותר מאפשרות אחת
    if ((selectedOption && teacherText) || (selectedOption && semelText) || (teacherText && semelText)) {
      setErrorMessage('עליך לבחור רק אפשרות אחת');
      setOpen(true);
      return;
    }
  
    // בודק אם המשתמש לא מילא כלום
    if (!selectedOption && !teacherText && !semelText) {
      setErrorMessage('עליך לבחור את אחת האפשרויות');
      setOpen(true);
      return;
    }
  
    // בודק איזו אפשרות נבחרה ומתבצעת התאמה בהתאם
    if (selectedOption) {
      console.log(`נעבור לגן ${selectedOption}`);
      setMosadName(selectedOption);
    } 
    if (teacherText) {
      console.log(`נעבור לגן ${teacherText}`);
      setTeacherName(teacherText);
    } 
    if (semelText) {
      console.log(`נעבור לגן ${semelText}`);
      setSemelMosad(semelText);
    }
  };
  
  
  

  const handleCancel = ()=>{
    setMosadName(undefined);
    setTeacherName('');
    setSemelMosad('');
    setTeacherText('');
    setSemelText('');
    setSelectedOption('');
  }

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Container sx={{ height: '100vh' }}>
<Typography mt={10} mb={2} variant="h3" color="appPink.main">
        רשימת תלמידים
      </Typography>
      <Typography mt={2} mb={2} variant="h5" color="appPink.main">
        בחר אפשרות אחת בלבד 
      </Typography>
      <form onSubmit={handleSubmit}>
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <FormControl>
        <InputLabel>שם גן</InputLabel>
        <Select
  value={selectedOption}
  onChange={(e) => setSelectedOption(e.target.value)}
  sx={{width : '200px' }}
>
  <MenuItem value="">בחר מוסד</MenuItem>
  {mosads.map((mosad) => (
    <MenuItem key={mosad.id} value={mosad.Name}>{mosad.Name}</MenuItem>
  ))}
</Select>

      </FormControl>
      
      <TextField
        label="שם גננת"
        placeholder="שם גננת"
        value={teacherText}
        onChange={(e) => setTeacherText(e.target.value)}
      />
      
      <TextField
        label="סמל מוסד"
        placeholder="סמל מוסד"
        value={semelText}
        onChange={(e) => setSemelText(e.target.value)}
      />
    </Box>
    
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <Button variant="contained" color="primary" type="submit">שלח</Button>
      <Button variant="contained" color="secondary" onClick={handleCancel}>נקה בחירה</Button>
    </Box>
  </Box>
</form>

<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{
          backgroundColor: 'white',
          border: '2px solid #000',
          padding: '20px',
        }}>
          {/* תוכן ההודעה בחלונית הקופצת */}
          <h2 id="simple-modal-title">שגיאה</h2>
          <p id="simple-modal-description">{errorMessage}</p>
          <Button onClick={handleClose}>סגור</Button>
        </div>
      </Modal>



{mosads.length > 0 && students.length === 0 && (
  <Typography mt={10} mb={2} variant="h3" color="appPink.main">
  עכשיו אפשר לבחור גן
</Typography>
)}
{mosads.length > 0 && students.length > 0 && (
  <DataGrid
    rows={students}
    columns={columns}
    getRowId={(row) => row.id}
    disableSelectionOnClick
    // onRowClick={({ row }) => {
    //   if (!isSmUp) {
    //     openDetailsDialog();
    //     setSelectedItem(row);
    //   }
    // }}
    initialState={{
      sorting: {
        sortModel: [{ field: "createdAt", sort: "desc" }],
      },
    }}
    sort
    loading={students === undefined}
    sx={{
      ...dataGridSx,
      width: { xs: "500px", sm: 1 },
      px: { xs: 1.5, sm: 3 },
      py: 1.5,
      ".MuiDataGrid-columnHeaderTitle": {
        color: "appPink.main",
      },
      ".MuiDataGrid-columnHeaders": {
        borderColor: "appPink.main",
      },
      ".MuiDataGrid-cell:focus": {
        outline: "none !important",
      },
    }}
  />
)}


</Container>
  );
};

export default AllStudentsPage;
