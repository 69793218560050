import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { appFetch } from "./fetch";

export const getPriceString = (price) =>
  String(price).replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,");

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export async function downloadFirestorageFile(id) {
  const res = await appFetch(`/api/file?id=${id}`, {
    defaultContentType: true,
  });
  if (!res.ok) throw new Error();
  const data = await res.blob();
  const filename = id;
  var a = document.createElement("a");
  a.href = window.URL.createObjectURL(data);
  a.download = filename;
  a.click();
  a.remove();
}

export const getOperationStatus = (operation) => {
  if (operation.status) return operation.status;
  // calc default status by codeType
  else if (operation.typeCode === 200 || operation.typeCode === 220) {
    if (operation.invoiceFileId) return "waiting-for-confirm";
    else return "waiting";
  }
};
