import { createBrowserRouter, Navigate } from "react-router-dom";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import Layout from "./components/layout/Layout";
import NonUserRoute from "./components/routing/NonUserRoute";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import PageNotFoundPage from "./pages/PageNotFoundPage";
import UsersPage from "./pages/UsersPage";
import CardsPage from "./pages/CardsPage";
import OperationsPage from "./pages/OperationsPage";
import MyProfilePage from "./pages/MyProfilePage";
import PaymentsPage from "./pages/PaymentsPage";
import StudentPage from "./pages/StudentPage";
import ConfirmedOperationsPage from "./pages/ConfirmedOperationsPage";
import BudgetsPage from "./pages/BudgetsPage";
import InvoicesPage from "./pages/InvoicesPage";
import RemindersPage from "./pages/RemindersPage"

import SessionsPage from "./pages/authorityPages/SessionsPage";
import AllPaymentsPage from "./pages/authorityPages/AllPaymentsPage";
import AllStudentsPage from "./pages/authorityPages/AllStudentsPages";
import AllCoordinatorStudentsPage from "./pages/coordinatorPages/AllCoordinatorStudentsPage";
import MosadPage from "./pages/authorityPages/MosadPage";
import OperatorsPage from "./pages/authorityPages/OperatorsPage";
import CreditCardsPage from "./pages/authorityPages/CreditCardsPage";

import TranzilaTransactions from "./pages/TranzilaTransactions";
import HokBankPage from "./pages/HokBankPage";
import CoordinatorDashboard from "./pages/coordinatorPages/CoordinatorDashboad";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"login"} />,
  },
  {
    path: "/login",
    element: (
      <NonUserRoute>
        <LoginPage />
      </NonUserRoute>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <NonUserRoute>
        <ForgotPasswordPage />
      </NonUserRoute>
    ),
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute />,
    children: [
      { index: true, element: <BudgetsPage /> },
      { path: "my-profile", element: <MyProfilePage /> },
      { path: "activities", element: <DashboardPage /> },
      { path: "payments", element: <PaymentsPage /> },
      { path: "students", element: <StudentPage /> },
      { path: "operators", element: <ConfirmedOperationsPage /> },
      { path: "invoices", element: <InvoicesPage /> },
    ],
  },
  {
    path: "/admin",
    element: <ProtectedRoute role={0} />,
    children: [
      { index: true, element: <Navigate to={"users"} /> },
      { path: "users", element: <UsersPage /> },
      { path: "cards", element: <CardsPage /> },
      { path: "operations", element: <OperationsPage /> },
      { path: "reminders", element: <RemindersPage /> },
      { path: "invoices", element: <TranzilaTransactions /> },
      { path: "hokBankPay", element: <HokBankPage /> },
      { path: "*", element: <PageNotFoundPage /> },
    ],
  },
  {
    path: "/authority",
    element: <ProtectedRoute role={1} />,
    children: [
      {index: true, element: <MosadPage /> },
      { path: "operators", element: <OperatorsPage /> },
      { path: "payments", element: <AllPaymentsPage /> },
      { path: "allStudents", element: <AllStudentsPage /> },
      { path: "activities", element: <SessionsPage /> },
      { path: "invoices", element: <CreditCardsPage /> },
      { path: "*", element: <PageNotFoundPage /> },
    ],
  },
  {
    path: "/coordinator",
    element: <ProtectedRoute role={3} />,
    children: [
      {index: true, element: <CoordinatorDashboard /> },
      { path: "students", element: <AllCoordinatorStudentsPage /> },
      { path: "*", element: <PageNotFoundPage /> },
    ],
  },
  {
    path: "*",
    element: (
      <Layout>
        <PageNotFoundPage />
      </Layout>
    ),
  },
]);

export default router;
