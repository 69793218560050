import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Backdrop, Button, Stack, CircularProgress } from "@mui/material";
import { useUser } from "../../hooks/useUser";
import { appFetch } from "../../utils/fetch";
import { useSnackbar } from "notistack";
import FileCopyIcon from '@mui/icons-material/FileCopy';

const drawerWidth = 240;

export const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowY: "scroll",
    height: "100vh",
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function AdminLayout({ children }) {
  const { logout, user } = useUser();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [appLoaderOpen, setAppLoaderOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  console.log(user);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const syncDataHandler = async () => {
    try {
      setAppLoaderOpen(true);
      const res = await appFetch("/api/sync-db-from-googlesheets");
      if (!res.ok) throw new Error();
      enqueueSnackbar("הנתונים נטענו בהצלחה");
    } catch (error) {
      enqueueSnackbar("שגיאה", { variant: "error" });
    } finally {
      setAppLoaderOpen(false);
    }
  };

  const NAV_BUTTONS = [
    { label: "משתמשים", path: "users", icon: <PersonIcon /> },
    { label: "כרטיסים", path: "cards", icon: <CreditCardIcon /> },
    { label: "פעולות אשראי", path: "operations", icon: <ReceiptLongIcon /> },
    { label: "חשבוניות", path: "invoices", icon: <FileCopyIcon /> },
    { label: "תזכורות", path: "reminders", icon: <FileCopyIcon /> },
    { label: "הוראות קבע", path: "hokBankPay", icon: <FileCopyIcon /> },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* HEADER */}
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color={"white"} noWrap component="div">
            אזור ניהול
          </Typography>
          <Button
            onClick={syncDataHandler}
            color="appYellow"
            variant="outlined"
            sx={{ color: "white", ml: "auto", mr: 3 }}
          >
            טען נתונים
          </Button>
          <Backdrop
            sx={{ bgcolor: "#000b", color: "#fff", zIndex: 100000000 }}
            open={appLoaderOpen}
          >
            <Stack alignItems={"center"} spacing={2}>
              <Typography color="inherit">הנתונים יטענו בעוד כדקה</Typography>
              <CircularProgress color="inherit" />
            </Stack>
          </Backdrop>
          <Button onClick={logout} color="appPurple" sx={{ color: "white" }}>
            התנתק
          </Button>
        </Toolbar>
      </AppBar>
      {/* DRAWER */}
      <Drawer
        sx={{
          zIndex: 1000,
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        PaperProps={{
          sx: {
            transform: open
              ? "translateX(0px) !important"
              : `translateX(-${drawerWidth}px) !important`,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {NAV_BUTTONS.map(({ label, path, icon }) => {
            const isActive = location.pathname.includes("/admin/" + path);
            return (
              <ListItem key={label} disablePadding>
                <ListItemButton
                  onClick={() => navigate(path)}
                  sx={{ bgcolor: isActive ? "#eee" : "transparent" }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      {/* MAIN */}
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}
