import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Container, Grid, Stack, TextField, Typography } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { validateByRules } from "../utils/validators";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [success, setSuccess] = useState(false);

  const onSubmit = async () => {
    const invalid = validateForm();
    if (invalid) {
      return;
    }

    setLoading(true);
    setServerError("");
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
    } catch (err) {
      const errorCode = err.code;
      const errorMessage = err.message;
      console.error(err);
      if (errorCode === "auth/user-not-found") {
        setServerError("User not found.");
      } else {
        setServerError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const hasErrors = validateByRules("email", email);
    return hasErrors;
  };

  return (
    <Container>
      <Grid container justifyContent="center" align="center">
        {!success ? (
          <Stack>
            <Typography variant="h1" marginY={4} gutterBottom component="h1">
              שכחתי סיסמא
            </Typography>
            <Stack spacing={2} alignItems="center">
              <TextField
                placeholder="כתוב את המייל שלך"
                value={email}
                name="email"
                onChange={(ev) => setEmail(ev.target.value)}
                fullWidth
              />
            </Stack>
            <Typography color="error" height="1em">
              {serverError}
            </Typography>
            <LoadingButton
              onClick={onSubmit}
              loading={loading}
              variant="contained"
            >
              שלח מייל לאיפוס סיסמא
            </LoadingButton>
          </Stack>
        ) : (
          <Stack mt={15}>
            <Typography variant="h4" component="h1" fontWeight="700">
              תודה!
            </Typography>
            <Typography variant="h5" fontWeight="400" sx={{ mb: 4 }}>
              בדוק את המייל שלך (גם בספאם)
            </Typography>
          </Stack>
        )}
      </Grid>
    </Container>
  );
}

export default ForgotPasswordPage;
