import { Navigate, useLocation } from "react-router";
import { useUser } from "../../hooks/useUser";

function NonUserRoute({ children }) {
  const { user } = useUser();
  let location = useLocation();

  const url =
  //location.state?.from?.pathname || user?.role > 0 ? "/dashboard" : "/admin";
  location.state?.from?.pathname || 
  (user?.role === 0 
    ? "/admin" 
    : user?.role === 1 
    ? "/dashboard" 
    : user?.role === 2 
    ? "/authority" 
    : user?.role === 3 
    ? "/coordinator" 
    : "/dashboard");


  if (user) {
    return <Navigate to={url} replace />; 
  } else {
    return children;
  }
}

export default NonUserRoute;
