import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { fireAuth } from "../plugins/firebase";
import { appFetch } from "../utils/fetch";

export const UserContext = createContext({
  user: null,
  logout: null,
  setUser: null,

});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [mosad, setMosad] = useState(null);
  const[organization,setOrganization]= useState('');
  const [mosads, setMosads] = useState([]);
  const [filterdMosads, setFilterdMosads] = useState([]);

  useEffect(() => {
    onAuthStateChanged(fireAuth, async (user) => {
      if (user) {
        const res = await appFetch("/api/auth/login");
        if (!res.ok) {
          alert("ארעה שגיאה, נא לפנות לשירות הלקוחות")
          setUser(null);
          return;
        }
        const data = await res.json();
        setUser(data.user);
      } else {
        setUser(null);
        // User is signed out
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = async () => {
    fireAuth.signOut();
//    window.localStorage.clear()
  };
  


  return (
    <UserContext.Provider value={{ user, setUser, logout, mosad, setMosad, mosads, setMosads, filterdMosads, setFilterdMosads ,organization,setOrganization}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
