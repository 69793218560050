import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { useForm } from "react-hook-form";
import { appFetch } from "../../utils/fetch";
import HookFormInput from "../HookFormInput";
import { useSnackbar } from "notistack";

function ChangePasswordDialog({ open, onClose, user, title, onError }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password1: "",
      password2: "",
    },
    context: {},
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const onCloseHandler = () => {
    reset();
    onClose();
  };

  const getValidationError = (pass1, pass2) =>
    pass1 !== pass2
      ? "הסיסמאות אינן זהות"
      : pass1.length < 6
      ? "סיסמא קצרה מ-6 תווים"
      : "";

  const onSubmit = async (formData) => {
    const error = getValidationError(formData.password1, formData.password2);
    if (error) return enqueueSnackbar(error, { variant: "error" });

    try {
      setLoading(true);
      const res = await appFetch(`/api/user/${user._id}/change-password`, {
        method: "PUT",
        body: JSON.stringify({ password: formData.password1 }),
      });
      if (!res.ok) throw new Error();
      onCloseHandler();
      enqueueSnackbar("הסיסמא עודכנה");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("אירעה שגיאה", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onCloseHandler}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCloseHandler}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            color={"white"}
            component="div"
          >
            {title || `שינוי סיסמא - ${user?.firstName}`}
          </Typography>
        </Toolbar>
      </AppBar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          p={3}
          spacing={1.5}
          display="flex"
          alignItems="center"
          maxWidth={350}
          mx="auto"
        >
          <HookFormInput
            control={control}
            type="password"
            errors={errors}
            label="סיסמא"
            fieldKey="password1"
          />
          <HookFormInput
            control={control}
            type="password"
            errors={errors}
            label="כתוב שוב"
            fieldKey="password2"
          />
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            sx={{ mt: 3 }}
            fullWidth
          >
            עדכן סיסמא
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
}

export default ChangePasswordDialog;
