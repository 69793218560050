import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography,Box,Chip} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import appConfig from "../utils/appConfig";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../hooks/useUser";
import useSWR from "swr";
import { swrFetcher } from "../utils/fetch";
import { column } from "stylis";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/free-solid-svg-icons'

function PaymentsPage() {
  
  const { mosad } = useContext(UserContext);;
  const url = appConfig.apiUrls.getPaymentsData + mosad.ID;

  const [alertDialogOpen, setAlertDialogOpen ] = useState(false);
  const [dialogText, setDialogText ] = useState("");
  const [onConfirmFunc, setOnConfirmFunc] = useState(null);

  const [data, setData ] = useState([]);
  const [load, setLoad ] = useState(true);
  const [err, setErr ] = useState(null);


  useEffect( () => { 
      fetch(url)
        .then((res) => res.json()
        .then( d => setData(d)))
        .catch(err => setErr(err))
        .finally( f => setLoad(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

 const update_payments = (type, arr) => {

    fetch(appConfig.apiUrls.confirmActivityPay, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: arr, type: type }),
    }).then((response) => {
      if (response.ok) {
        let new_ob = [...data];
        new_ob.map((item, key) => {
          if (arr.includes(item.id)) {
            if (type === "confirm") {
              new_ob[key]["Reportingstatus"] = "אושר על ידי הגננת";
            }
            if (type === "refused") {
              new_ob[key]["Reportingstatus"] = "תשלום סורב";
            }
          }
          return null;
        });
        setData(new_ob);
      } else {
        alert("לא הצלחנו לעדכן את הרשומה");
      }
    }).finally( n => resetFn() )
  };

  const resetFn = () => {
    setAlertDialogOpen(false);
    setDialogText("");
    setOnConfirmFunc(null);
    setDetailsDialogOpen(false);
  }

  const resetFn1 = () => {
    setDetailsDialogOpen(false);
  }


  function openAlertDialogHandler(type, onConfirm, selectedItem) {
      setOnConfirmFunc(() => onConfirm);
      if( type === "confirm" ){
        setDialogText(`האם את בטוחה שאת רוצה לאשר את התשלום עבור  ${selectedItem?.Name}?`);
      }
      if( type === "refused" ){
        setDialogText(`האם את בטוחה שאת רוצה לסרב תשלום ל - ${selectedItem?.Name}?`);
      }
      setAlertDialogOpen(true);
  }

  const single_payment = (id, type) => {
    update_payments(type, [id]);
  };
  

  const getColumns = ( actions = false, includedColumns = []) => {
    const columns = [
    //   {
    //   headerName: "",
    //   field: "c",
    //   width: 20
    // },
    // {
    //   headerName: "תיאור",
    //   field: "Name",
    //   width: 100 
    // },
    // {
    //   headerName: "תאריך",
    //   field: "Date_of_activation",
    //   valueGetter: ({ value }) =>
    //     new Date(value).toLocaleDateString("he"),
    // },
    // {
    //   headerName: "סטטוס",
    //   field: "Reportingstatus",
    //   width: 150 
    // },
    // {
    //   headerName: "סוג",
    //   field: "TypeActivity",
    //   width: 150 
    // },
    // {
    //   headerName: "מפעיל",
    //   field: "Operator",
    //   width: 150,
    //   renderCell: (params) => <> {
    //    params.value.name
    //   }</>
    // },
    // {
    //   headerName: "סכום",
    //   field: "AmountToCalculate", 
    //   renderCell: (params) => <> {params.value} &#8362;</>
    // }
    { field: 'Date_and_Type', headerName: 'Date of activation and Type of activity', width: 100, renderCell: (params) => (
      <div>
        <div>{params.row.Date_of_activation}</div>
        <div>&#8362;{params.row.AmountToCalculate}</div>
      </div>
    )
  },
  { field: 'Operator_and_AmountToCalculate', headerName: 'Operator and Amount to calculate', width: 165, renderCell: (params) => (
    <div>
<div style={{ textAlign: 'left' }}>{params.row.Operator.name}</div>
<div style={{ textAlign: 'right' }}>{params.row.TypeActivity}</div>
</div>

  )
}
  ]

    

    if( actions ){
      columns.push({headerName: "פעולה",
      width: 100,
      field: "actions",
      //type: "actions",
      renderCell: (params) => (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Button
            color="success"
            variant="contained"
            sx={{ height: "20px" }} 
            onClick={(e) => openAlertDialogHandler('confirm' , () => single_payment(params.row.id, "confirm"), params?.row)}
          >
            אישור
          </Button >
          <Button 
            color="error"
            variant="contained"
            sx={{height: "20px" }}
            onClick={(e) => openAlertDialogHandler('refused', () => single_payment(params.row.id, "refused"), params?.row)}
            >
            סירוב
          </Button>
          </div>
      )})
    }
    if (includedColumns.length > 0) {
      // Filter columns based on includedColumns array
      return columns.filter((col) => includedColumns.includes(col.field));
    }

    return columns;
  }

  
  const data_01 = data?.filter((item) => item.Reportingstatus === "חדש").map((i, k) => ({...i, c: k+1}));
  const data_02 = data?.filter((item) => item.Reportingstatus !== "חדש").map((i, k) => ({...i, c: k+1}));
  
  const { data: myPayments, mutate } = useSWR(url,
    swrFetcher
  );

  const feesPayments = [];
  const groupedPaymentsByStatus = {
    חדש: [], 
    "אושר על ידי הגננת": [],
    "נדחה על ידי הגננת": [],
    "בוצע תשלום": []
  };

  myPayments?.forEach((item) => {
    if (item.typeCode === 722) {
      // totalfees += item.amount;
      feesPayments.push(item);
    } else {
      const paymentStatus = item.Reportingstatus;
      groupedPaymentsByStatus[paymentStatus].push(item);
    }
  });

  const [newPayments, setNewPayments] = useState(groupedPaymentsByStatus["חדש"].length);
  const [notNewPayments, setNotNewPayments] = useState(myPayments?.length - groupedPaymentsByStatus["חדש"].length);
  
  useEffect(() => {
    setNewPayments(groupedPaymentsByStatus["חדש"].length);
    setNotNewPayments(myPayments?.length - groupedPaymentsByStatus["חדש"].length);
  }, [groupedPaymentsByStatus, myPayments]);
  


const [rowData, setRowData] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const onConfirmButton = () => {
    onConfirmFunc();
    // Calculate the count of new payments
    const newPaymentsCount = groupedPaymentsByStatus["חדש"].length;
    
    // Calculate the count of not new payments
    let notNewPaymentsCount = 0;
    for (const status in groupedPaymentsByStatus) {
      if (status !== "חדש") {
        notNewPaymentsCount += groupedPaymentsByStatus[status].length;
      }
    }
    
    // Update the state variables
    setNewPayments(newPaymentsCount);
    setNotNewPayments(notNewPaymentsCount);
  };
  

  return (
    <Container sx={{ width: "100%" }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography mt={2} mb={2} variant="h3" color="appPink.main">
        תשלומים ממתינים לאישור ({newPayments})
       </Typography>
       
       
      </div>

      <DataGrid
        autoHeight 
        rows={data_01 || []}
        columns={[...getColumns(true, ["Date_and_Type","Operator_and_AmountToCalculate", "actions"])]}
        onRowClick={(params) => {
          setRowData(params.row);
            setDetailsDialogOpen(!detailsDialogOpen);
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        loading={load}
        headerHeight={0}
      />

      <Typography mt={2} mb={2} variant="h3" color="appPink.main">
      תשלומים שטופלו ({notNewPayments})
      </Typography>
      {/*  כאן התחלתי לעבוד על סנן בדף תשלומים ממש כמו שיש בדף פעולות באשראי. צריך להמשיך לעבוד על זה  */}
      {/* <Box
        display={"flex"}
        alignItems={"center"}
        p={0.5}
        gap={0.5}
        // mb={1.5}
        sx={{
          border: "1px solid #eee",
          borderRadius: 20,
          width: "fit-content",
          margin: 1.5,
        }}
      > */}
        {/* <StatusChip
          onClick={() => setOperationStatus("confirmed")}
          label={`אושר (${getOperationCount("confirmed")})`}
          isActive={operationStatus === "confirmed"}
        />
        <StatusChip
          onClick={() => setOperationStatus("waiting-for-confirm")}
          label={`בהמתנה (${getOperationCount("waiting-for-confirm")})`}
          isActive={operationStatus === "waiting-for-confirm"}
        />
        <StatusChip
          onClick={() => setOperationStatus("rejected")}
          label={`נדחה (${getOperationCount("rejected")})`}
          isActive={operationStatus === "rejected"}
        /> */}
      {/* </Box> */}
      <DataGrid
  autoHeight
  rows={data_02 || []}
  columns={[...getColumns(false, ["Date_and_Type","Operator_and_AmountToCalculate"])]}
  onRowClick={(params) => {
    setRowData(params.row);
      setDetailsDialogOpen(!detailsDialogOpen);
  }}
  initialState={{
    sorting: {
      sortModel: [{ field: "createdAt", sort: "desc" }],
    },
  }}
  sort
  loading={load}
  headerHeight={0}
/>

<Dialog
        open={alertDialogOpen}
        onClose={resetFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">שימי לב!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={resetFn}>ביטול</Button>
          <Button variant="contained" onClick={onConfirmButton} autoFocus>אישור</Button>
        </DialogActions>
      </Dialog>


<Dialog sx={{ whiteSpace: 'pre' }}
  open={detailsDialogOpen&&!alertDialogOpen}
  onClose={resetFn1}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title" style={{ color: 'green' }}>
    <FontAwesomeIcon icon={faCoins} style={{ marginLeft: '10px', marginBottom: '1px' }} />
    פרטי הפעולה
  </DialogTitle>
  <DialogContent style={{ minWidth: '300px' , justifyContent: 'flex-start' }}> 
    <DialogContentText id="alert-dialog-description">
    {rowData && (
  <span>
    <span style={{marginBottom: '8px', fontSize: '1.2rem', fontWeight: 'bold' }}>תיאור               </span>
    <span style={{ textAlign: 'left', width: '70%' , fontSize: '1.1rem'}}>{rowData.Name}</span>
    <br />
    <span style={{marginBottom: '8px', fontSize: '1.2rem', fontWeight: 'bold' }}>תאריך              </span>
    <span style={{textAlign: 'left', width: '70%' , fontSize: '1.1rem'}}>{rowData.Date_of_activation}</span>
    <br />
    <span style={{marginBottom: '8px', fontSize: '1.2rem', fontWeight: 'bold' }}>סטטוס             </span>
    <span style={{textAlign: 'left', width: '70%' , fontSize: '1.1rem'}}>{rowData.Reportingstatus}</span>
    <br />
    <span style={{marginBottom: '8px', fontSize: '1.2rem', fontWeight: 'bold' }}>סוג                   </span>
    <span style={{textAlign: 'left', width: '70%' , fontSize: '1.1rem'}}>{rowData.TypeActivity}</span>
    <br />
    <span style={{marginBottom: '8px', fontSize: '1.2rem', fontWeight: 'bold' }}>מפעיל             </span>
    <span style={{textAlign: 'left', width: '50%' , fontSize: '1.1rem'}}>{rowData.Operator.name}</span>
    <br />
    <span style={{marginBottom: '8px', fontSize: '1.2rem', fontWeight: 'bold' }}>סכום                </span>
    <span style={{textAlign: 'left', width: '50%' , fontSize: '1.1rem'}}>&#8362;{rowData.AmountToCalculate}</span>
  </span>
)}


  </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={resetFn1}>סגור</Button>
        </DialogActions>
      </Dialog>

       
    </Container>
  );
}

// function StatusChip({ label, isActive, onClick }) {
//   return (
//     <Chip
//       size="medium"
//       label={label}
//       color={isActive ? "appGreen" : "disabledText"}
//       variant={isActive ? "filled" : "outlined"}
//       onClick={onClick}
//       sx={{ width: "5.5rem", border: "none" }}
//     />
//   );
// }

export default PaymentsPage;

