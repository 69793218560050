import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useForm } from "react-hook-form";
import { appFetch, swrFetcher } from "../../utils/fetch";
import HookFormInput from "../HookFormInput";
import { useSnackbar } from "notistack";
import useSWR from "swr";

function CreateUserDialog({ open, onClose, onSuccess, onError }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      organization: "",
      role:1
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState("");
  const [passwordHasCopied, setPasswordHasCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const rolesOption=[{_id:0,title:"אדמין"},{_id:1,title:"משתמש"},{_id:2,title:"רשות"},{_id:3,title:"רכז"}];
  
  const organizations = useSWR('/api/organizations', swrFetcher );
  const organizationOption = organizations.data?.map( o => ({ _id:o._id, title: o.name }));

  const onCloseHandler = () => {
    reset();
    setPassword(false);
    onClose();
  };

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      const res = await appFetch(`/api/user`, {
        method: "POST",
        body: JSON.stringify(formData),
      });
      if (!res.ok) throw new Error();
      const data = await res.json();
      setPassword(data.password);
      onSuccess();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("אירעה שגיאה", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onCloseHandler}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCloseHandler}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            color={"white"}
            component="div"
          >
            יצירת משתמש
          </Typography>
        </Toolbar>
      </AppBar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          p={3}
          spacing={1.5}
          display="flex"
          alignItems="center"
          maxWidth={350}
          mx="auto"
        >
          <HookFormInput
            control={control}
            errors={errors}
            label="הרשאה"
            fieldKey="role"
            isSelect={true}
            options={rolesOption}
          />

          <HookFormInput
            control={control}
            errors={errors}
            label="ארגון"
            fieldKey="organization"
            isSelect={true}
            options={organizationOption}
          />
          <HookFormInput
            control={control}
            errors={errors}
            label="שם פרטי"
            fieldKey="firstName"
          />
          <HookFormInput
            control={control}
            errors={errors}
            label="שם משפחה"
            fieldKey="lastName"
          />
          <HookFormInput
            control={control}
            errors={errors}
            label="טלפון"
            fieldKey="phone"
          />
          <HookFormInput
            control={control}
            errors={errors}
            label="דוא״ל"
            fieldKey="email"
          />
          <HookFormInput
            control={control}
            errors={errors}
            label="סיסמא"
            fieldKey="password"
          />
          <HookFormInput
            control={control}
            errors={errors}
            label="סמל מוסד"
            fieldKey="mosadCode"
          />
          {!password ? (
            <Button
              disabled={loading}
              variant="contained"
              type="submit"
              sx={{ mt: 3 }}
              fullWidth
            >
              צור משתמש
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ mt: 3, textTransform: "none" }}
              fullWidth
              startIcon={<ContentCopyIcon />}
              onClick={() => {
                navigator.clipboard.writeText(password);
                setPasswordHasCopied(true);
              }}
            >
              {!passwordHasCopied ? `סיסמא - ${password}` : "הסיסמא הועתקה!"}
            </Button>
          )}
        </Stack>
      </form>
    </Dialog>
  );
}

export default CreateUserDialog;
