import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { appFetch } from "../utils/fetch"; // בדוק שהנתיב נכון

const HokBankPage = () => {
  const [payments, setPayments] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // שורות מסומנות
  const { enqueueSnackbar } = useSnackbar();

 


  // פונקציה להבאת נתונים מהשרת
  const fetchPayments = async () => {
    try {
      const paymentType = encodeURIComponent("הוק בנקאית");
      const res = await appFetch(
        `api/zohoPayments/paymentsByPayTypeForAdmin/${paymentType}`,
        {
          method: "GET",
        }
      );
      if (!res.ok) throw new Error("Failed to fetch payments");

      const data = await res.json();
      if (data && data.length > 0) {
        setPayments(data); // שמירת הנתונים במצב
      } else {
        setPayments([]); // במידה ואין תשלומים
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
      enqueueSnackbar("שגיאה בטעינת התשלומים", { variant: "error" });
    }
  };

  // קריאה ל-API בעת טעינת הקומפוננטה
  useEffect(() => {
    fetchPayments();
  }, []);
  
  // פונקציה להראות את התשלומים שנבחרו
  const handleShowPayments = async () => {
    if (selectedRows.length === 0) {
      enqueueSnackbar("לא נבחרו תשלומים", { variant: "error" });
      return;
    }
  
    const selectedPayments = selectedRows.map(rowId => payments.find(p => p.payeeNumber === rowId)).filter(p => p);
  
    try {
      const response =await appFetch('/api/zohoPayments/generateMasavFile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ payments: selectedPayments }),
      });
  
      if (!response.ok) throw new Error('Failed to generate masav file');
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'masav_file.bin');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error generating masav file:", error);
      enqueueSnackbar("שגיאה ביצירת קובץ המסב", { variant: "error" });
    }
  };
  
  // פונקציה לנהל את הבחירה בשורות

  // עמודות הטבלה
  const columns = [
    
    { field: "Name", headerName: "שם התשלום", width: 250 },
    { field: "payeeName", headerName: "שם המשלם", width: 150 },
    { field: "payeeId", headerName: "תעודת זהות", width: 150 },
    { field: "paymentDate", headerName: "תאריך", width: 200 },
    { field: "bankName", headerName: "שם הבנק", width: 150 },
    { field: "branchId", headerName: "מספר סניף", width: 150 },
    { field: "accountId", headerName: "מספר חשבון", width: 150 },
  ];
  
  return (
    <Box sx={{ height: "80vh", maxWidth: "100%", overflow: "auto" }}>
      <DataGrid
        rows={payments}
        columns={columns}
        getRowId={(row) => row.payeeNumber}
        components={{ Toolbar: GridToolbar }}
        loading={!payments.length}
        onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
        checkboxSelection
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleShowPayments}
        sx={{ mt: 2 }}
      >
        הורד קובץ למס"ב
      </Button>
    </Box>
  );
  
};

export default HokBankPage;
