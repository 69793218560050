import { Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";
import { Fragment, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactsIcon from '@mui/icons-material/Contacts';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { DrawerHeader, Main } from "./AdminLayout";
import logo2 from "../../assets/images/logo2.png"
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import appConfig from "../../utils/appConfig";
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { UserContext } from "../../hooks/useUser";
import  LoadingScreen from "../LoadingScreen";
import useSWR from "swr";
import { swrFetcher } from "../../utils/fetch";
import { appFetch } from "../../utils/fetch";

const drawerWidth = 240;

const CoordinatorLayout = ({ children}) => {

  const { mosad, setMosad, user, logout, mosads, setMosads, filterdMosads, setFilterdMosads,organization,setOrganization } = useContext(UserContext);
  


  useEffect( () => {
    if( !organization ){
        (async () => {
            try {

                // Get general info:
               
                // Get info from WP REST API:
                if( user?.organization?.organizationId ){
                  console.log(user);
                  // let response = await fetch(`${appConfig.apiUrls.getOrganization}${user.organization.organizationId}`);
                  // const organization = await response.json();
                  // setOrganization(organization);
                  // console.log(organization);
  
                  let response = await appFetch('api/zohoOrganizations/details'); 
                  setOrganization(response);
                  if (!response.ok) {
                    throw new Error('Failed to fetch mosads');
                  }
                  const organization = await response.json();
                  organization.pages= ["activities","payments","students","operators","allStudents"];
                  organization.logo= "https://pay.billagan.co.il/wp-content/uploads/2023/07/logo.png"
                  setOrganization(organization);
                  console.log(organization);
  
                  const res1 = await appFetch('api/zohoOrganizations/mosads'); 
                  if (!res1.ok) {
                    throw new Error('Failed to fetch mosads');
                  }
                  let data1 = await res1.json();
                  data1.sort((a, b) => a.Name.localeCompare(b.Name));
                  setMosads(data1);
                  console.log(data1);

  
                  //  סינון המערך לפי טלפון או מייל
                  data1 = data1.filter(mosad => {
                    //return mosad?.field6 === user?.phone;
                    return  mosad?.uid === user?.uid;
                  });
                  
  
                  setFilterdMosads(data1);
                  console.log(data1);
  
                }
              } catch (error) {
              console.log(error);
              alert("שגיאה בטעינת הנתונים" );
              logout();
            }
        })();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const theme = useTheme();

  const [open, setOpen] = useState( !appConfig.is_mobile );
  const navigate = useNavigate();
  const location = useLocation();
  
  

  const NAV = [
    { label: "ראשי", path: "", icon: <ReceiptLongIcon /> },
    { label: "רשימת תלמידים", path: "students", icon: <ReceiptLongIcon /> },
  ];

  if( !organization ){
    return <LoadingScreen />;
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
      <CssBaseline />

      
        <Drawer className="drawer-mobile" sx={{
            // zIndex: 1,
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          PaperProps={{
            sx: {
              transform: open
                ? "translateX(0px) !important"
                : `translateX(-${drawerWidth}px) !important`,
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}>
          <DrawerHeader>
            {organization?.logo && <img src={organization?.logo} alt="" style={{width: "80px", marginLeft: "auto", height: "55px", objectFit: "contain" }}/> }
          <IconButton onClick={() => setOpen(!open)}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {NAV.map(({ label, path, icon }) => {
              if( path && organization.pages && !organization.pages.includes(path) ) return <Fragment key={label}></Fragment>;
              const isActive = location.pathname === "/coordinator" + (path ? "/" + path : path );
              // add validation
              return (
                <ListItem key={label} disablePadding onClick={() => appConfig.is_mobile ? setOpen(false) : ""}>
                  <ListItemButton
                    onClick={() => navigate(path)}
                    sx={{ bgcolor: isActive ? "#eee" : "transparent" }}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <div style={{position: "absolute", bottom: 15, textAlign: "center", display: "flex", flexDirection: "column", gap: "10px", alignItems: "center"}}>
            <img style={{ width: "60%",}} src={logo2} alt="" />
          </div>
        </Drawer>

        <Main className="main-mobile" open={open} style={{padding: 0}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{ mr: 2, ml: 2, ...(open && { display: "none" }), position: "absolute", top: "20px" }}>
            <MenuIcon />
          </IconButton>
          <Header />
          <Box component={"main"} px={{ xs: 0, sm: 1.5 }} mt={2}>
            {children}
          </Box>
        </Main>
      </Box>
      <Footer />
    </>
  );
};

export default CoordinatorLayout;
