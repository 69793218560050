import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';  // ספרייה לחישוב תאריכים
import { appFetch } from "../utils/fetch";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Card
} from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import appConfig from '../utils/appConfig';

const TranzilaTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [zohoPayments, setZohoPayments] = useState([]);
  const [missingTransactions, setMissingTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [clickedButton, setClickedButton] = useState('');
  const [date, setDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateError, setDateError] = useState('');
  const [hasClickedTerminal, setHasClickedTerminal] = useState(false);
  const [masofs, setMasofs] = useState([]);
  const [filterMissing, setFilterMissing] = useState(false);
  const initialText = "סנן תוצאות שמופיעות בזוהו"
  const [buttonText, setButtonText] = useState(initialText);
  const [selectedMasof, setSelectedMasof] = useState(null);
  const [transaction, setTransaction] = useState([]);
  const [transactionAsmachta, setTransactionAsmachta] = useState({});
  const [masofMethod, setMasofMethod] = useState('');


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchMasofs = async () => {
      try {
        const response = await axios.get(`/api/tranzilaRoutes/getMasofsNames`);
        setMasofs(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching masofs names:', error);
      }
    };

    fetchMasofs();
  }, []);

  const fetchTransactions = async (terminalName) => {
    setLoading(true);
    setFetchError('');
    setTransactions([]);
    setMissingTransactions([]);

    const calculatedStartDate = startDate || moment().subtract(1, 'months').format('YYYY-MM-DD');
    const calculatedEndDate = endDate || moment().format('YYYY-MM-DD');
    setDate(calculatedStartDate);

    try {
      if (!calculatedStartDate) {
        return;
      }

      console.log(calculatedStartDate);
      console.log(calculatedEndDate);
      console.log(terminalName);

      // Fetch Transactions from Tranzila
      const response = await axios.get(`/api/tranzilaRoutes/getTransactions/${calculatedStartDate}/${calculatedEndDate}/${terminalName}`);
      console.log(response.data);
      // עובר על התשובה ובודק האם זו עסקה ללא תשלומים ומעדכן
if (response.data && Array.isArray(response.data)) {
  response.data.forEach(item => {
    if (item.number_of_payments === "0") {
      item.number_of_payments = "1";
      item.first_payment_amount = item.amount; 
    }
  });
}
console.log(response.data);
      setTransactions(response.data);

       // יצירת מערך חדש עם הערכים של index מתוך כל אובייקט במערך המקורי
       const ids = response.data.filter(transaction => transaction.transtatus === "1").map(transaction => transaction.index);


      // Fetch Zoho Payments
      if(ids.length===0){
        ids.push('0000');
      }
      const res = await appFetch(`api/zohoPayments/paymentsByDates/${terminalName}`, {
        method: 'POST', // שינוי ל-POST
        headers: {
          'Content-Type': 'application/json', // כדי לציין שהגוף הוא בפורמט JSON
        },
        body: JSON.stringify({ ids }), // מעבירים את ids בפיילואד
      });
      if (!res.ok) {
        const errorData = await res.json();
        console.error('Error fetching dates:', errorData.message);
        setErrorMessage(errorData.message);
        setOpen(true);
        setLoading(false);
        return;
      }
      const data = await res.json();
      console.log(data);
      setMissingTransactions(data);

      

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchError('Failed to fetch data');
      setLoading(false);
    }
  };

  const calculateTotalAmount = (transactions) => {
    const totalAmount = transactions.reduce((sum, transaction) => sum + transaction.amount / 100, 0);
    return totalAmount.toFixed(2);
  };
  

  const handleClickOpen = (masof) => {
    setClickedButton(masof.terminal);
    setSelectedMasof(masof);
    setMasofMethod(masof.paymentMethod);
    console.log(masof.paymentMethod)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDateError('');
  };

  const handleFetch = (terminalName) => {
    const now = moment().format('YYYY-MM-DD');

    if (startDate && moment(startDate).isAfter(now)) {
      setDateError('תאריך התחלה לא יכול להיות בעתיד');
      return;
    }
    if (endDate && moment(endDate).isAfter(now)) {
      setDateError('תאריך סיום לא יכול להיות בעתיד');
      return;
    }
    if (startDate && endDate && moment(endDate).isSameOrBefore(startDate)) {
      setDateError('תאריך סיום צריך להיות מאוחר יותר מתאריך התחלה');
      return;
    }
    
    setOpen(false);
    setDateError('');
    setFilterMissing(false);
    setButtonText(initialText);
    setHasClickedTerminal(true);
    fetchTransactions(terminalName);
  };

  const handleFilterMissing = () => {
    setFilterMissing(prev => !prev);
    setButtonText(filterMissing? initialText:"בטל סינון")
    console.log('Filter button clicked', filterMissing); 
    console.log(transactions); 
  };

  function extractNumberFromString(str, length) {
    // מסיר את כל התווים שאינם ספרות
    const cleanedStr = str.replace(/[^\d]/g, '');
    const regex = new RegExp(`\\b\\d{${length}}\\b`);
    const match = cleanedStr.match(regex);
    return match ? match[0] : null;
}
let myData;

const handleCreateTransaction = async (transaction) => {
  console.log(transaction);
  console.log(transaction.user_defined_7);
  let id = extractNumberFromString(transaction.user_defined_7, 9);
  console.log(`יצירת עסקה בזוהו עבור: ${transaction.index}`);
  console.log(id);
  id = "038803664"; // שורה שתהיה חייבת לעוף!!

  try {
    const res = await appFetch(`api/zohoMosads/schedulingById/${id}/${appConfig.year}`, {
      method: 'GET',
    });
    if (!res.ok) {
      const errorData = await res.json();
      console.error('Error fetching dates:', errorData.message);
      setErrorMessage(errorData.message);
      setOpen(true);
      setLoading(false);
      return;
    }
    myData = await res.json(); // הקצאת ערך למשתנה data בתוך הבלוק של try
    myData= myData.data[0]
    console.log(myData);
  } catch (error) {
    console.error('Error in handleCreateTransaction:', error);
    setErrorMessage(error.message);
    setOpen(true);
    setLoading(false);
  }
 
    handleAddScheduling(transaction);
  
};

const handleAddScheduling = async (transaction) => {
  const paymentData = {
    data: [{
      Name: `תשלום ${myData?.contactid.name}`,
      Email: transaction.user_defined_3||"אין אימייל",
      contactid: myData?.contactid.id,
      paydate:  `${transaction.transaction_date}T${transaction.transaction_time}`,
      IDcardholder: transaction.credit_card_owner_id,
      //payment_plan: transaction.payment_plan,
      type_pay: `${masofMethod}`,
      pay_notes: transaction?.user_defined_7,
      field2: myData.id,
      field3: transaction?.tranmode.startsWith('A')? "תשלום":(transaction?.tranmode.startsWith('C')?"זיכוי":""),
      field: transaction.index,
      field1: myData.field2,
      expmon: transaction.expiration_month,
      child_terminal:transaction.child_terminal,
      expyear:transaction.expiration_year,
      number_of_payments:transaction.number_of_payments,
      token:transaction.credit_card_token,
      first_payment:transaction.first_payment_amount,
      other_payments:transaction.other_payment_amount,
      amount:(transaction.amount/100),
      field8: "תקין",
    }]
  };

  
  console.log('Sending new payment:', paymentData);

  try {
    const res = await appFetch('/api/zohoPayments/addPayment', {
      method: 'POST',
      body: JSON.stringify(paymentData),
    });

    if (!res.ok) {
      const errorData = await res.json();
      console.error('Error adding payment:', errorData.message);
      setErrorMessage(errorData.message);
      setOpen(true);
      setLoading(false);
      return;
    }

    const data = await res.json();
    console.log('Payment added successfully:', data);
  } catch (error) {
    console.error('Error in handleAddScheduling:', error.message);
    setErrorMessage(error.message);
    setOpen(true);
    setLoading(false);
  }
};







  const handleCreateAsmacta = async (transaction) => {
    console.log(`יצירת אסמכתא בזוהו עבור: ${clickedButton}`);
    try {
      const response = await axios.get(`/api/tranzilaRoutes/getTransactionByIndex/${clickedButton}/${transaction.index}`);
      setTransaction(response.data);
      console.log(response.data[0]);
      setTransactionAsmachta(prev => ({ ...prev, [transaction.index]: response.data[0].amount }));
    } catch (error) {
      console.error('Error fetching transaction:', error);
    }
  };
  
  
  

  const baseColumns = [
    {
      field: "index",
      headerName: "אינדקס",
      minWidth: 80,
    },
    {
      field: "amount",
      headerName: "סכום",
      minWidth: 80,
      valueGetter: (params) => (params.row.amount / 100).toFixed(2),
    },    
    {
      field: "transtatus",
      headerName: "סטטוס",
      minWidth: 80,
    },
    {
      field: "transaction_date",
      headerName: "תאריך עסקה",
      minWidth: 80,
    },
    {
      field: "payment_plan",
      headerName: "סוג תשלום",
      minWidth: 80,
    },
    {
      field:  "payment_method",
      headerName: "צורת תשלום",
      minWidth: 80,
      valueGetter: (params) => selectedMasof.paymentMethod ? selectedMasof.paymentMethod : "payment_method",
    },
    {
      field: "number_of_payments",
      headerName: "כמות תשלומים",
      minWidth: 80,
    },
    {
      field: "first_payment_amount",
      headerName: "סכום תשלום ראשון",
      minWidth: 80,
    },
    {
      field: "other_payment_amount",
      headerName: "סכום שאר תשלומים",
      minWidth: 80,
    },
    {
      field: "createAsmacta",
      headerName: "מספר אסמכתא ",
      minWidth: 150,
      renderCell: (params) => (
        transactionAsmachta[params.row.index] ? (
          <span>{transactionAsmachta[params.row.index]}</span>
        ) : (
          <Button
            variant="outlined"
            onClick={() => handleCreateAsmacta(params.row)}
          >
            הבא אסמכתא
          </Button>
        )
      )
    },
    {
      field: "authorization_number",
      headerName: "מספר אישור אשראי",
      minWidth: 80,
    },
    {
      field: "user_defined_7",
      headerName: "הערות/תיאור מוצר",
      minWidth: 80,
    },
    {
      field: "existsInZoho",
      headerName: "האם קיים בזוהו",
      minWidth: 80,
      valueGetter: (params) => loading? "": (params.row.child_terminal===""? "חגי??":(params.row.transtatus==="0"?"עסקה נכשלה": missingTransactions.includes(params.row.index) ? "לא" : "כן")),
    }
  ];
  
  const columns = !filterMissing ? baseColumns : [
    ...baseColumns,
    {
      field: "createInZoho",
      headerName: "הקם עסקה בזוהו",
      minWidth: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => handleCreateTransaction(params.row)}
        >
          הקם
        </Button>
      )
    }
  
  ];



  const buttonStyle = {
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    margin: '0 10px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  };

  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  };

  const buttonsContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  };

  return (
    <div style={containerStyle}>
      <h1>עסקאות טרנזילה</h1>
      <Box
        display="flex"
        justifyContent="center"
        my={2}
        sx={{ gap: 1.5 }}
      >
        <InsightCard
          color="appPink.main"
          label="מספר עסקאות"
          value={transactions.length}
        />
        <InsightCard
          color="appGreen.main"
          label="סכום עסקאות כולל"
          value={calculateTotalAmount(transactions)}
        />
      </Box>

      <div style={buttonsContainerStyle}>
      {masofs.map((masof) => (
          <button key={masof.terminal} style={buttonStyle} onClick={() => handleClickOpen(masof)}>
              {masof.name}
          </button>
))}

      </div> 

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"הכנס תאריכים"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="תאריך התחלה"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="תאריך סיום"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {dateError && <Typography color="error">{dateError}</Typography>}
          <Typography variant="body2">
            אפשר להשאיר את התאריכים ריקים ואז יתקבלו הפעולות מהחודש האחרון, אפשר גם למלא רק תאריך התחלה ואז יתקבלו הפעולות מתאריך ההתחלה ועד היום.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ביטול
          </Button>
          <Button onClick={() => handleFetch(clickedButton)} color="primary" autoFocus>
            אישור
          </Button>
        </DialogActions>
      </Dialog>
      {!hasClickedTerminal && (
        <p>לחץ על שם מסוף כדי לקבל נתוני עסקאות.</p>
      )}
      {hasClickedTerminal && loading && <h3>טוען נתונים מטרנזילה או מזוהו...</h3>}
      {hasClickedTerminal && fetchError && <p style={{ color: 'red' }}>{fetchError}</p>}
      {hasClickedTerminal && transactions.length === 0 && !loading && !fetchError && (
        <h2>אין עסקאות טרנזילה לתקופה המבוקשת</h2>
      )}
      {transactions.length > 0 && (
        <>
          <h2 style={{ fontSize: '1.5em', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  {startDate && endDate
    ? `נתוני ${transactions.length} עסקאות טרנזילה ממסוף: ${clickedButton} בין התאריכים: ${startDate} - ${endDate}`
    : startDate
      ? `נתוני ${transactions.length} עסקאות טרנזילה ${clickedButton} בין התאריכים: בין התאריכים: ${startDate} - ${moment().format('YYYY-MM-DD')}`
      : `נתוני ${transactions.length} עסקאות טרנזילה ${clickedButton} מהחודש האחרון`}
  <Button
    onClick={handleFilterMissing}
    variant="outlined"
    style={{ marginLeft: '10px', fontSize: '0.6em' }}
  >
    {buttonText}
  </Button>
</h2>

         <Box sx={{ height: "80vh", maxWidth: "100%", overflow: "auto" }}>
         <Box sx={{ height: "80vh", maxWidth: "100%", overflow: "auto" }}>
  <DataGrid
    rows={filterMissing ? transactions.filter(t => missingTransactions.includes(t.index)) : transactions}
    columns={columns}
    getRowId={(row) => row.index}
    initialState={{
      sorting: {
        sortModel: [{ field: "createdAt", sort: "desc" }],
      },
    }}
    loading={loading}
  />
</Box>

</Box>
        </>
      )}
    </div>
  );
};

export function InsightCard({ value, color, label }) {
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '6rem',
          textAlign: 'center',
          width: { xs: '30%', md: '19%' },
          padding: { xs: '10px' },
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { xs: '1.4rem', md: '2.5rem' } }}
          color={color}
        >
          {value}
        </Typography>
        <Typography
          margin={'5px'}
          sx={{ fontSize: { xs: '1rem', md: '1.4rem' } }}
          variant="h4"
        >
          {label}
        </Typography>
      </Card>
    );
  }
  

export default TranzilaTransactions;
