import React, { useState } from "react";

import { Box, Chip, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddCardIcon from "@mui/icons-material/AddCard";
import KeyIcon from "@mui/icons-material/Key";
import useSWR from "swr";
import { useSnackbar } from "notistack";

import { appFetch, swrFetcher } from "../utils/fetch";
import AppFAB from "../components/AppFAB";
import CreateUserDialog from "../components/dialogs/CreateUserDialog";
import { useConfirm } from "material-ui-confirm";
import ConnectCardToUserDialog from "../components/dialogs/ConnectCardToUserDialog";
import ChangePasswordDialog from "../components/dialogs/ChangePasswordDialog";

const UsersPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [isOpenCreateUserDialog, setIsOpenCreateUserDialog] = useState(false);
  const closeCreateUserDialog = () => setIsOpenCreateUserDialog(false);
  const openCreateUserDialog = () => setIsOpenCreateUserDialog(true);

  const [isOpenChangePasswordDialog, setIsOpenChangePasswordDialog] =
    useState(false);
  const closeChangePasswordDialog = () => setIsOpenChangePasswordDialog(false);
  const openChangePasswordDialog = () => setIsOpenChangePasswordDialog(true);

  const [isOpenConnectUserToCardDialog, setIsOpenConnectUserToCardDialog] =
    useState(false);
  const closeConnectUserToCardDialog = () =>
    setIsOpenConnectUserToCardDialog(false);
  const openConnectUserToCardDialog = () =>
    setIsOpenConnectUserToCardDialog(true);

  const baseUrl = "/api/user";

  const { data, mutate } = useSWR(baseUrl, swrFetcher);
  

  const columns = [
    {
      field: "firstName",
      headerName: "שם פרטי",
      minWidth: 80,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "שם משפחה",
      minWidth: 80,
      editable: true,
    },
    {
      field: "phone",
      headerName: "טלפון",
      minWidth: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "דוא״ל",
      minWidth: 180,
    },
    {
      field: "organization",
      headerName: "ארגון",
      minWidth: 180,
      valueGetter: ({ value }) => value?.name,
    },
    {
      field: "role",
      headerName: "הרשאה",
      minWidth: 80,
      valueGetter: ({ value }) => (value === 0 ? "מנהל" : "משתמש"),
    },
    {
      field: "creditCards",
      headerName: "כרטיסים",
      flex: 1,
      renderCell: ({ row }) => (
        <Box>
          {row.creditCards?.length
            ? row.creditCards.map((card) => (
                <Chip
                  key={card._id}
                  label={card.number}
                  onDelete={() => deleteCardFromUserHandler(row, card)}
                />
              ))
            : null}
        </Box>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 50 * 2,
      cellClassName: "actions",
      getActions: ({ row }) => [
        <IconButton
          aria-label="add"
          onClick={() => {
            setSelectedItem(row);
            openConnectUserToCardDialog();
          }}
          key={`add-btn-${row._id}`}
        >
          <AddCardIcon />
        </IconButton>,
        <IconButton
          aria-label="add"
          onClick={() => {
            setSelectedItem(row);
            openChangePasswordDialog();
          }}
          key={`password-btn-${row._id}`}
        >
          <KeyIcon />
        </IconButton>,
      ],
    },
  ];

  const onSuccess = (message = "השינויים נשמרו") => {
    enqueueSnackbar(message);
    mutate();
  };
  const onError = (err) => {
    enqueueSnackbar(err.message || "אירעה שגיאה", { variant: "error" });
  };

  async function onCellEditCommit({ id, field, value }) {
    try {
      const res = await appFetch(`${baseUrl}/${id}`, {
        method: "PUT",
        body: JSON.stringify({ [field]: value }),
      });
      if (!res.ok) throw new Error();
      enqueueSnackbar("השינויים נשמרו");
      mutate();
    } catch (err) {
      enqueueSnackbar("אירעה שגיאה", { variant: "error" });
    }
  }

  async function deleteCardFromUserHandler(user, card) {
    try {
      await confirm({
        description: `לנתק את כרטיס מספר ${card.number} מ-'${user.firstName} ${user.lastName}'?`,
      });
      const res = await appFetch(
        `/api/card/${card._id}/detach-from-user/${user._id}`,
        {
          method: "delete",
        }
      );
      if (!res.ok) throw new Error();
      onSuccess();
    } catch (err) {
      onError(err);
    }
  }

  const addItemHandler = () => {
    setSelectedItem(null);
    openCreateUserDialog();
  };




  return (
    <Box sx={{ height: "80vh", maxWidth: "100%", overflow: "auto" }}>
      
      <DataGrid
        rows={data || []}
        columns={columns}
        onCellEditCommit={onCellEditCommit}
        getRowId={({ _id }) => _id}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sort
        loading={!data}
      />

      <CreateUserDialog
        onClose={closeCreateUserDialog}
        open={isOpenCreateUserDialog}
        onSuccess={() => mutate()}
      />
      <ConnectCardToUserDialog
        user={selectedItem}
        open={isOpenConnectUserToCardDialog}
        onClose={closeConnectUserToCardDialog}
        onSuccess={() => mutate()}
      />
      <ChangePasswordDialog
        user={selectedItem}
        open={isOpenChangePasswordDialog}
        onClose={closeChangePasswordDialog}
      />
      <AppFAB label="הוסף משתמש" onClick={addItemHandler} />
    </Box>
  );
};

export default UsersPage;
