import { useState, useRef, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckIcon from "@mui/icons-material/Check";
import { Button, Stack, TextField, Container, Divider, Typography, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { appFetch } from "../utils/fetch";
import { UserContext } from "../hooks/useUser";
import { format } from 'date-fns';

function InvoicesPage() {

    const { mosad } = useContext(UserContext);

    const { enqueueSnackbar } = useSnackbar();
    const [filename, setFilename] = useState("");
    const [note, setNote] = useState("");
    const [budget, setBudget] = useState("");
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const fileInputRef = useRef(null);
    const noteInputRef = useRef(null);
    const formRef = useRef(null);

    useEffect( () => {
        (async ()=>{
            const res = await appFetch( `/api/file/invoice`);
            const data = await res.json();
            setInvoices( data );
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mosad,invoices]);


    const onCloseHandler = () => {
        fileInputRef.current.value = null;
        noteInputRef.current.value = null;
        setFilename("");
        setNote("");
        setBudget("");
    };

    const onSubmit = async (ev) => {
        ev.preventDefault();

        if( !filename ){
            enqueueSnackbar("יש לצרף קובץ", { variant: "error" });
            return;
        }
        try {
            setLoading(true);
            const formData = new FormData(formRef.current);

            const res = await appFetch(
                `/api/file/invoice`,
                {
                method: "POST",
                body: formData,
                defaultContentType: true,
                }
            );
        if (!res.ok) throw new Error();
            enqueueSnackbar("החשבונית נשמרה");
            onCloseHandler();
        } catch (error) {
            console.error(error);
            enqueueSnackbar("אירעה שגיאה", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };
    return (
        <Container className="BudgetsPage" sx={{ marginBottom: 3 }}>
            <Typography mt={0} mb={2} variant="h3" color="appPink.main">חשבוניות</Typography>
            <Typography mt={2} mb={0} variant="h5" color="appPink.main">העלאת חשבונית</Typography>

            <Stack maxWidth={450} margin={"auto"} component={"form"} onSubmit={onSubmit} ref={formRef} p={3} spacing={2} display="flex" alignItems="center" >
                <FormControl fullWidth>
                    <InputLabel>סעיף תקציבי</InputLabel>
                    <Select name="budget" value={budget} label="סעיף תקציבי" onChange={e => setBudget(e.target.value )} >
                        {mosad.budget_items?.map( b => <MenuItem key={b.id} value={b.id}>{b.value}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField fullWidth name="note" label="הערה" value={note} onChange={e=> setNote(e.target.value)} size="small" ref={noteInputRef} />
                <Button disabled={loading} variant={!filename ? "text" : "outlined"} startIcon={!filename ? <AttachFileIcon /> : <CheckIcon />}
                    onClick={() => fileInputRef.current.click()}
                    fullWidth >
                
                {!filename ? "בחר קובץ" : "נבחר"}
                </Button>
                    <input
                    type="file"
                    name="invoice"
                    accept="image/*,application/pdf"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(ev) => { setFilename(ev.target.files?.[0]?.name); }} />

                <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<UploadFileIcon />} fullWidth>העלה</LoadingButton>
            </Stack>

            {invoices.length ? (
                <>
                    <Divider />
                    <Typography mt={2} mb={0} variant="h5" color="appPink.main" sx={{ marginLeft: '20px' }}> רשימת חשבוניות</Typography>
                    <TableContainer component={Paper}>
                    <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>שם הקובץ</TableCell>
                                    <TableCell>סעיף תקציבי</TableCell>
                                    <TableCell>תאריך העלאה</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.map((invoice, index) => (
                                    <TableRow key={invoice._id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            <Link href={invoice.file} target="_blank" rel="noreferrer">
                                                {invoice.note || 'קובץ'} 
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                        {mosad.budget_items && invoice.budget !== undefined && invoice.budget !== null
                                            ? mosad.budget_items[parseInt(invoice.budget)]?.value ?? 'לא נבחר'
                                            : 'לא נבחר'}
                                        </TableCell>
                                        <TableCell>{formatDate(invoice.updatedAt)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Divider />
                </>
            ) : ""}
        </Container>
    );
}

export default InvoicesPage;
