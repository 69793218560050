import { createTheme, ThemeProvider } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const boxShadow = "0px 4px 16px rgba(138, 0, 212, 0.08)";
const borderRadius = ".7rem";

const theme = createTheme({
  palette: {
    primary: { main: "#D527B7" }, // =appPink.main
    appPurple: { main: "#8A00D4" },
    appPink: { main: "#D527B7", 300: "#F782C2" },
    appBlack: { main: "#303D45", contrastText: "#ffffff" },
    appGray: { main: "#E3E3E3", 700: "#555" },
    disabledText: { main: "#999", contrastText: "#ffffff" },
    appYellow: { main: "#F9C46B" },
    appGreen: { main: "#84F5BF", contrastText: "#303D45" },
  },
  typography: {
    direction: "rtl",
    fontFamily: "Ploni, Arial, Helvetica, sans-serif",

    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.1rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.7rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.4rem",
    },
    h5: {
      fontSize: "1.2rem",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: "appBlack.main",
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius,
          boxShadow,
        },
      },
    },
  },
});

export const dataGridSx = {
  boxShadow,
  borderRadius,
  border: "none",
  ".MuiDataGrid-columnHeaderTitle": {
    color: "appPink",
  },
};

export const ThemeProviderCustom = ({ children }) => (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
);
