import { Box, Collapse, Container, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import appConfig from "../utils/appConfig";
import { Fragment, useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ConfirmedOperationsPage() {
  
  const url = appConfig.apiUrls.operatorsData;
  const [data, setData ] = useState([]);

  useEffect( () => {
    fetch(url)
    .then((res) => res.json()
    .then( d => setData(d)))
    .catch(err => alert("שגיאה בטעינת הנתונים"));
  },[url])

  const data_01 = data.map((i, k) => ({...i, c: k+1}));

  return (
    <Container>

      <Typography mt={10} mb={2} variant="h3" color="appPink.main">
        פרטי המפעילים
      </Typography>

      <Box sx={{ height: "60vh", overflow: "auto", maxWidth: "100%" }}>

        <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>שם</TableCell>
                    <TableCell>שם עסק</TableCell>
                    <TableCell>טלפון</TableCell>
                    <TableCell>מייל</TableCell>
                    <TableCell>סוג</TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { !data.length ? <TableRowsLoader rowsNum={10} cellNum={6} /> : data_01.map((row) => (
                    <Row key={row.ID} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Box>
      
    </Container>
  );
}
export default ConfirmedOperationsPage;

function Row( props ) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{row.c}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.Esek_name}</TableCell>
        <TableCell>{row.mobile}</TableCell>
        <TableCell>{row.phone}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>
          {( row.area || row.details ) && <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> }
        </TableCell>
        
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                אזור:
              </Typography>
              <Typography variant="p" component="div">
                {row.area}
              </Typography>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                פרטים:
              </Typography>
              <Typography variant="p" component="div">
                {row.details}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}


const TableRowsLoader = ({ rowsNum, cellNum }) => {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(cellNum)].map((row, j) => <TableCell key={j}><Skeleton animation="wave" variant="text" /></TableCell> )}
    </TableRow>
  ));
};