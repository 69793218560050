import {
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { dataGridSx } from "../../plugins/mui";
import { appFetch } from "../../utils/fetch";
import { useSnackbar } from "notistack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { downloadFirestorageFile } from "../../utils/tools";
import { useConfirm } from "material-ui-confirm";
import UploadInvoiceDialog from "../dialogs/UploadInvoiceDialog";

function OperationsTable({ data, hideInvoiceActions, mutate }) {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingUploadId, setLoadingUploadId] = useState(""); // row._id | ''

  const [isOpen_detailsDialog, setIsOpen_detailsDialog] = useState(false);
  const closeDetailsDialog = () => setIsOpen_detailsDialog(false);
  const openDetailsDialog = () => setIsOpen_detailsDialog(true);

  const [uploadInvoiceDialogOpen, setUploadInvoiceDialogOpen] = useState(false);
  const openUploadInvoiceDialog = () => setUploadInvoiceDialogOpen(true);
  const closeUploadInvoiceDialog = () => setUploadInvoiceDialogOpen(false);

  const desktopColumns = [
    {
      field: "creditCard",
      headerName: "מספר כרטיס",
      minWidth: 130,
    },
    {
      field: "date",
      headerName: "תאריך",
      valueGetter: ({ value }) => value ? new Date(value) : null, // שינוי כאן
      valueFormatter: ({ value }) => value ? new Date(value).toLocaleDateString("he-IL") : '', // שינוי כאן
      width: 150,
    },
    {
      field: "description",
      headerName: "תיאור",
      width: isSmUp ? 250 : 200,
    },
    {
      field: "providerName",
      headerName: "שם ספק",
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "סכום",
      width: isSmUp ? 80 : 70,
    },
    {
      field: "currency",
      headerName: "מטבע",
      minWidth: 60,
    },
  ];

  if (!hideInvoiceActions)
    desktopColumns.push({
      type: "actions",
      field: "actions",
      headerName: "חשבונית",
      getActions: ({ row }) => {
        if (![200, 220].includes(row.typeCode)) return [];
        const isLoading = loadingUploadId === row._id;

        const downloadInvoiceHandler = async () => {
          try {
            setLoadingUploadId(row._id);
            const data = await downloadFirestorageFile(row.invoiceFileId);
          } catch (error) {
            console.error(error);
            enqueueSnackbar("אירעה שגיאה", { variant: "error" });
          } finally {
            setLoadingUploadId("");
          }
        };

        const deleteInvoiceHandler = async () => {
          await confirm({
            description: `למחוק את חשבונית על סך ${row.amount}?`,
            confirmationText: "מחק",
          });
          try {
            setLoadingUploadId(row._id);
            const res = await appFetch(
              `/api/file/delete-invoice?id=${row.invoiceFileId}`,
              {
                method: "DELETE",
              }
            );
            if (!res.ok) throw new Error();
            enqueueSnackbar("החשבונית נמחקה");
            mutate();
          } catch (error) {
            console.error(error);
            enqueueSnackbar("אירעה שגיאה", { variant: "error" });
          } finally {
            setLoadingUploadId("");
          }
        };

        return !row.invoiceFileId
          ? [
              <IconButton
                key="upload"
                color="appPink"
                onClick={() => {
                  setSelectedItem(row);
                  openUploadInvoiceDialog();
                }}
              >
                <UploadFileIcon />
              </IconButton>,
            ]
          : [
              <IconButton
                key="download"
                color="success"
                onClick={downloadInvoiceHandler}
              >
                <DownloadIcon />
              </IconButton>,
              <IconButton
                key="delete"
                color="error"
                onClick={deleteInvoiceHandler}
                disabled={isLoading}
              >
                <DeleteIcon />
              </IconButton>,
            ];
      },
    });

  const mobileColumns = desktopColumns.filter(({ field }) =>
    ["actions", "date", "amount", "providerName"].includes(field)
  );
  // move actions column to start at mobile view
  mobileColumns.splice(-1, 1);
  mobileColumns.unshift(desktopColumns.at(-1));

  const columns = isSmUp ? desktopColumns : mobileColumns;
  columns.at(-2).flex = 1;

  return (
    <Box sx={{ height: "50vh", overflow: "auto", maxWidth: "100%" }}>
      <DataGrid
        rows={data || []}
        columns={columns}
        getRowId={({ _id }) => _id}
        disableSelectionOnClick
        onRowClick={({ row }) => {
          if (!isSmUp) {
            openDetailsDialog();
            setSelectedItem(row);
          }
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        sort
        loading={data === undefined}
        sx={{
          ...dataGridSx,
          width: { xs: "500px", sm: 1 },

          px: { xs: 1.5, sm: 3 },
          py: 1.5,
          ".MuiDataGrid-columnHeaderTitle": {
            color: "appPink.main",
          },
          ".MuiDataGrid-columnHeaders": {
            borderColor: "appPink.main",
          },
          ".MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          '.MuiDataGrid-cell[data-field="amount"] .MuiDataGrid-cellContent': {
            direction: "rtl",
          },
        }}
      />
      <UploadInvoiceDialog
        open={uploadInvoiceDialogOpen}
        onClose={closeUploadInvoiceDialog}
        selectedItem={selectedItem}
        onSuccess={() => mutate()}
      />
      {!isSmUp && selectedItem && (
        <Dialog
          onClose={closeDetailsDialog}
          open={isOpen_detailsDialog}
          PaperProps={{
            sx: { p: 3 },
          }}
        >
          <Stack gap={2}>
            {desktopColumns.map((col) => {
              return (
                <Box display={"flex"} gap={2} key={col.field}>
                  <Typography fontWeight={600}>{col.headerName} -</Typography>
                  {col.field === "actions" ? (
                    <IconButton
                      key="upload"
                      color="appPink"
                      onClick={() => {
                        setSelectedItem(selectedItem);
                        openUploadInvoiceDialog();
                      }}
                    >
                      <UploadFileIcon />
                    </IconButton>
                  ) : (
                    <Typography>
                      {col.valueGetter
                        ? col.valueGetter({
                            row: selectedItem,
                            value: selectedItem[col.field],
                          })
                        : selectedItem[col.field]}
                    </Typography>
                  )}
                </Box>
              );
            })}
            <Box display={"flex"} gap={2}>
              <Typography fontWeight={600}>{"סטטוס"} -</Typography>
              <Typography>{selectedItem["status"]}</Typography>
            </Box>
          </Stack>
        </Dialog>
      )}
    </Box>
  );
}
export default OperationsTable;
