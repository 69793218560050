import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@mui/material";
import { Container } from "@mui/system";
import LogoSrc from "../../assets/images/logo.png";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  return (
    <Container sx={{ mt: "auto" }}>
      <Box
        display="flex"
        flexDirection={{ xs: "row", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        textAlign="center"
        py={2.5}
        gap={{ xs: 3, sm: 0 }}
      >
        <Link color="appBlack.main" component={RouterLink} to="/policy" sx={{ marginLeft: '60px' }}>
          מדיניות
        </Link>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="center"
          textAlign="center"
          gap={{ xs: 0.2, sm: 1 }}
        >
          {/* <Box display="flex"> לפרטים: נוגה</Box> */}
          <Box display="flex" flexDirection="row" gap={1}> {/* Updated */}
  <Box display="flex">
    <a
      style={{
        color: "#D527B7",
        display: "flex",
        alignItems: "center",
      }}
      href={`tel:0585511320`}
      className="hover:underline flex items-center gap-1 justify-center "
    >
      <PhoneEnabledIcon style={{ fontSize: "17px"}} />
      0585511320
    </a>
  </Box>
  <Box display="flex">
    <a
      style={{
        color: "#D527B7",
        display: "flex",
        alignItems: "center",
      }}
      href={`https://wa.me/9720585511320?text=שלום`}
      className="hover:underline flex items-center gap-1 justify-center "
      target="_blank"
      rel="noreferrer"
    >
      <WhatsAppIcon style={{ fontSize: "20px", color: "green" }} />
   
    </a>
  </Box>
</Box>

        </Box>
        <img className="logo" src={LogoSrc} alt="logo" />
      </Box>
    </Container>
  );
};

export default Footer;
