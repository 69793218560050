import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Container, Stack, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import KeyIcon from "@mui/icons-material/Key";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import HookFormInput from "../components/HookFormInput";
import { useUser } from "../hooks/useUser";
import { appFetch } from "../utils/fetch";
import { useSnackbar } from "notistack";
import ChangePasswordDialog from "../components/dialogs/ChangePasswordDialog";

function MyProfilePage() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenChangePasswordDialog, setIsOpenChangePasswordDialog] =
    useState(false);
  const closeChangePasswordDialog = () => setIsOpenChangePasswordDialog(false);
  const openChangePasswordDialog = () => setIsOpenChangePasswordDialog(true);

  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      address: user.address,
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await appFetch(`/api/user/${user._id}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });
      if (!res.ok) throw new Error();
      setIsEditMode(false);
      enqueueSnackbar("השינויים נשמרו");
    } catch (error) {
      enqueueSnackbar("שגיאה", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Stack
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        spacing={2.5}
        maxWidth={400}
        mx="auto"
        mt={4}
        alignItems="center"
      >
        <HookFormInput
          control={control}
          errors={errors}
          fieldKey="firstName"
          label="שם פרטי"
          readOnly={!isEditMode}
          variant={!isEditMode ? "standard" : "filled"}
        />
        <HookFormInput
          control={control}
          errors={errors}
          fieldKey="lastName"
          label="שם משפחה"
          readOnly={!isEditMode}
          variant={!isEditMode ? "standard" : "filled"}
        />
        <HookFormInput
          control={control}
          errors={errors}
          fieldKey="phone"
          type="tel"
          label="פלאפון"
          readOnly={!isEditMode}
          variant={!isEditMode ? "standard" : "filled"}
        />
        <HookFormInput
          control={control}
          errors={errors}
          fieldKey="address"
          label="כתובת"
          readOnly={!isEditMode}
          variant={!isEditMode ? "standard" : "filled"}
        />

        <Typography style={{minWidth: "300px"}}><b>סמל מוסד:</b> {user.mosadCode}</Typography>

        {!isEditMode ? (
          <Button startIcon={<EditIcon />} onClick={() => setIsEditMode(true)}>
            עריכה
          </Button>
        ) : (
          <LoadingButton
            loading={loading}
            startIcon={<SaveIcon />}
            type="submit"
            variant="contained"
          >
            שמירה
          </LoadingButton>
        )}
        <Button
          startIcon={<KeyIcon />}
          onClick={() => {
            openChangePasswordDialog();
          }}
        >
          שנה סיסמא
        </Button>
      </Stack>
      <ChangePasswordDialog
        open={isOpenChangePasswordDialog}
        onClose={closeChangePasswordDialog}
        user={user}
        title="שינוי סיסמא"
      />
    </Container>
  );
}

export default MyProfilePage;
